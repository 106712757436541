// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

// Description: Mapping Model to map one AI model to one projectType

// Keep in sync C# and js frontend
// Source\HSA-Kit\Models\AIModel2ProjectTypeMapping.cs

/**
 * AIModel2ProjectTypeMapping class for AI Model to ProjectType mapping.
 */

// HSA imports
import {
  AudioInferenceParameters,
  ImageInferenceParameters,
  InferenceParameters,
} from "../../common/components/IAMConfig";
import { TargetDataFormat } from "../../common/components/AITrainingSettings";
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  validateInEnum,
  validateInstance,
  validateType,
  ValidationType,
} from "../../common/utils/Utils";

export default class AIModel2ProjectTypeMapping {
  /**
   * Constructor for AIModel2ProjectTypeMapping.
   * @param {string} projectType ProjectType name.
   * @param {string} aiModel AI Model name.
   * @param {TargetDataFormat} targetDataFormat The type of the target format of the AI model.
   * @param {List[StructureMapping]} structureMappings Mapping of old structures to new Structures of selected project type.
   * @param {InferenceParameters} inferenceParameters Model specific parameters.
   * @param {Int} outerStructureId OuterId of the mapping.
   * @param {bool} isActive Is model active.
   */
  constructor(
    projectType,
    aiModel,
    targetDataFormat,
    structureMappings,
    inferenceParameters,
    outerStructureId,
    isActive
  ) {
    // Input validation
    validateType("projectType", projectType, ValidationType.String);
    validateType("aiModel", aiModel, ValidationType.String);
    validateInEnum("targetDataFormat", targetDataFormat, TargetDataFormat);
    validateInstance("structureMappings", structureMappings, Array);
    structureMappings.forEach((structureMapping) => {
      validateInstance("structureMapping", structureMapping, StructureMapping);
    });

    validateInstance(
      "inferenceParameters",
      inferenceParameters,
      InferenceParameters
    );

    validateType("outerStructureId", outerStructureId, ValidationType.Int);
    validateType("isActive", isActive, ValidationType.Bool);

    this.projectType = projectType;
    this.aiModel = aiModel;
    this.structureMappings = structureMappings;
    this.inferenceParameters = inferenceParameters;
    this.outerStructureId = outerStructureId;
    this.isActive = isActive;
  }

  /**
   * Create new AIModel2ProjectTypeMapping from an object without needing to define all parameters individually.
   * Accepts camelCase and PascalCase properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AIModel2ProjectTypeMapping contructor.
   * @param {object} obj An object containing all necessary properties of AIModel2ProjectTypeMapping.
   * @returns {AIModel2ProjectTypeMapping} New AIModel2ProjectTypeMapping.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    validateInstance("structureMappings", obj.structureMappings, Array);
    validateInEnum("targetDataFormat", obj.targetDataFormat, TargetDataFormat);

    // Depending on the targetDataFormat, the inferenceParameters must be set
    switch (obj.targetDataFormat) {
      case TargetDataFormat.Image:
        obj.inferenceParameters = ImageInferenceParameters.fromObject(
          obj.inferenceParameters
        );
        break;
      case TargetDataFormat.Audio:
        obj.inferenceParameters = AudioInferenceParameters.fromObject(
          obj.inferenceParameters
        );
        break;
      default:
        obj.inferenceParameters = InferenceParameters.fromObject(
          obj.inferenceParameters
        );
        break;
    }

    return new AIModel2ProjectTypeMapping(
      obj.projectType,
      obj.aiModel,
      obj.targetDataFormat,
      obj.structureMappings.map((structureMapping) =>
        StructureMapping.fromObject(structureMapping)
      ),
      obj.inferenceParameters,
      obj.outerStructureId,
      obj.isActive
    );
  }
}

export class StructureMapping {
  /**
   * Constructor for StructureMapping.
   * @param {Number} oldId Old structure id.
   * @param {Number} newId New structure id.
   */
  constructor(oldId, newId) {
    // Input validation
    if (oldId !== null) validateType("oldId", oldId, ValidationType.Int);
    if (newId !== null) validateType("newId", newId, ValidationType.Int);

    this.oldId = oldId;
    this.newId = newId;
  }

  /**
   * Create new StructureMapping from an object without needing to define all parameters individually.
   * Accepts camelCase and PascalCase properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per StructureMapping contructor.
   *
   * @param {object} obj An object containing all necessary properties of StructureMapping.
   * @returns {Structure} New StructureMapping.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    const oldId = obj.oldId;
    let newId = obj.newId;

    // explicitly allow "newId" to be null
    if (newId === undefined) {
      newId = null;
    }

    return new StructureMapping(oldId, newId);
  }
}
