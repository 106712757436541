// Copyright HS Analysis GmbH, 2023
// Author: Lukas Buess

// HSA imports

// Keep in sync with:
// Source\HSA-KIT\modules\hsa\core\ai\....py
// Source\HSA-KIT\ClientApp\src\common\components\....jsx

// HSA imports
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  validateInstance,
  validateType,
  ValidationType,
} from "../utils/Utils";

/**
 * All dataset specific parameters of an AI Training.
 * @param {String} os Operating system. Default: "No Information!".
 * @param {String} cpu Name of CPU. Default: "No Information!".
 * @param {String} gpu Name of GPU. Default: "No Information!".
 * @param {String} ram Amount of RAM. Default: "No Information!".
 */
export class SystemInformation {
  constructor(
    os = "No Information!",
    cpu = "No Information!",
    gpu = "No Information!",
    ram = "No Information!"
  ) {
    // Input validation
    validateType("os", os, ValidationType.String);
    validateType("cpu", cpu, ValidationType.String);
    validateType("gpu", gpu, ValidationType.String);
    validateType("ram", ram, ValidationType.String);

    this.os = os;
    this.cpu = cpu;
    this.gpu = gpu;
    this.ram = ram;
  }

  /**
   * Create a new SystemInformation from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per SystemInformation constructor.
   * @param {object} obj An object containing all necessary properties of SystemInformation.
   * @returns {SystemInformation} New SystemInformation.
   */
  static fromObject(obj) {
    return new SystemInformation(
      obj.os ?? obj.Os,
      obj.cpu ?? obj.Cpu,
      obj.gpu ?? obj.Gpu,
      obj.ram ?? obj.Ram
    );
  }
}

/**
 * Information of an AI Training.
 * @param {String} creationDate Date when training was started.
 * @param {String} completionDate Date when training was completed.
 * @param {SystemInformation} systemInformation Information about the system used for training.
 * @param {String} trainingStatus status of the AI Training.
 * @param {String} errorMessage Error message if training failed.
 */
export class AITrainingInformation {
  constructor(
    creationDate = "",
    completionDate = "",
    systemInformation = new SystemInformation(),
    trainingStatus = "pending",
    errorMessage = ""
  ) {
    // Input validation
    validateType("creationDate", creationDate, ValidationType.String);
    validateType("completionDate", completionDate, ValidationType.String);
    validateInstance("systemInformation", systemInformation, SystemInformation);
    validateType("trainingStatus", trainingStatus, ValidationType.String);
    validateType("errorMessage", errorMessage, ValidationType.String);

    // Set parameters
    this.creationDate = creationDate;
    this.completionDate = completionDate;
    this.systemInformation = systemInformation;
    this.trainingStatus = trainingStatus;
    this.errorMessage = errorMessage;
  }

  /**
   * Create a new AITrainingInformation from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AITrainingInformation constructor.
   * @param {object} obj An object containing all necessary properties of AITrainingInformation.
   * @returns {AITrainingInformation} New AITrainingInformation.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new AITrainingInformation(
      obj.creationDate,
      obj.completionDate,
      SystemInformation.fromObject(obj.systemInformation),
      obj.trainingStatus,
      obj.errorMessage
    );
  }
}
