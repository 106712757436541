import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import {
  TextField,
  MenuItem,
  FormControl,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";

const styles = {
  paddingContainer: {
    padding: 10,
  },
  formItem: {
    width: "100%",
    marginBottom: 20,
  },
  numberInput: {
    width: 50,
    marginRight: 10,
  },
  applyButton: {
    width: "100%",
  },
  hidden: {
    display: "none",
  },
};

class ProteomSettings extends Component {
  render() {
    const { classes, metaData, projectStringProperties, onChangeMetaData } =
      this.props;
    if (typeof projectStringProperties === "undefined") return <div />;
    return (
      <div className={classes.paddingContainer}>
        <FormControl className={classes.formItem}>
          <TextField
            name="Textfield"
            select
            label="Channel"
            onChange={(e) => onChangeMetaData("Channel", e.target.value)}
            value={metaData.Channel}
          >
            {projectStringProperties.Channel.split(",").map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl className={classes.formItem}>
          <TextField
            name="Textfield"
            select
            label="Proteome"
            onChange={(e) => onChangeMetaData("Proteome", e.target.value)}
            value={metaData.Proteome}
          >
            {projectStringProperties.Proteome.split(",").map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl component="fieldset" className={classes.hidden}>
          <FormLabel component="legend">Epitope type</FormLabel>
          <RadioGroup
            row
            aria-label="Epitope type"
            name="epitpetype"
            defaultValue="continuous"
          >
            <FormControlLabel
              value="continuous"
              control={
                <Checkbox
                  name="CheckboxContinuous"
                  onChange={(e) =>
                    onChangeMetaData("Continues", e.currentTarget.checked)
                  }
                  checked={Boolean(metaData.Continues)}
                />
              }
              label="Continuous"
            />
            <FormControlLabel
              value="discontinuous"
              control={
                <Checkbox
                  name="CheckboxDiscontinuous"
                  onChange={(e) =>
                    onChangeMetaData("Discontinues", e.currentTarget.checked)
                  }
                  checked={Boolean(metaData.Discontinues)}
                />
              }
              label="Discontinuous"
            />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formItem}>
          <FormLabel component="legend">Epitope length</FormLabel>
          <div>
            <TextField
              name="Textfield"
              style={{ width: 80 }}
              type="number"
              label="min"
              onChange={(e) => {
                if (e.target.value < metaData.EpitopeLengthMax) {
                  onChangeMetaData("EpitopeLengthMin", e.target.value);
                }
              }}
              value={metaData.EpitopeLengthMin}
              className={classes.numberInput}
            />
            <TextField
              name="Textfield"
              style={{ width: 80 }}
              type="Number"
              label="max"
              onChange={(e) => {
                if (e.target.value > metaData.EpitopeLengthMin) {
                  onChangeMetaData("EpitopeLengthMax", e.target.value);
                }
              }}
              value={metaData.EpitopeLengthMax}
              className={classes.numberInput}
            />
          </div>
        </FormControl>
        <FormControl component="fieldset" className={classes.formItem}>
          <FormLabel component="legend">
            Minimum score (do not show results with lower score)
          </FormLabel>
          <div>
            <TextField
              name="Textfield"
              style={{ width: 80 }}
              type="number"
              label="min"
              onChange={(e) => onChangeMetaData("MinScore", e.target.value)}
              inputProps={{ step: "0.01" }}
              value={metaData.MinScore}
              className={classes.numberInput}
            />
          </div>
        </FormControl>
      </div>
    );
  }
}

ProteomSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  metaData: PropTypes.object,
  projectProperties: PropTypes.object,
  projectStringProperties: PropTypes.object,
  onChangeMetaData: PropTypes.func,
};

export default withStyles(styles)(ProteomSettings);
