import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// HSA imports
import Backend from "../../common/utils/Backend";

const PreviewImage = ({ fileId }) => {
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    Backend.getFileInfo(fileId).then((file) => {
      setFileName(file.fileName);
    });
  }, [fileId]);

  return (
    <div style={{ display: "inline-block" }} key={fileId}>
      <div style={{ display: "inline-block" }}>
        <img
          style={{
            display: "block",
            background: "black",
            margin: "5px",
            objectFit: "contain",
          }}
          width="200"
          height="200"
          src={Backend.renderThumbnail(fileId)}
          alt=""
        />
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {fileName}
        </div>
      </div>
    </div>
  );
};

PreviewImage.propTypes = {
  fileId: PropTypes.string.isRequired,
};

export default PreviewImage;
