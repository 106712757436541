import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";

function CreateConfirmationDialog({ open, setOpen, setPending }) {
  const [evalOnly, setEvalOnly] = useState(false); //,

  function handleRadioChange(event) {
    setEvalOnly(event.target.value);
  }

  function handleClose(runJob) {
    if (runJob) {
      setPending(evalOnly);
    }
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Analysis Mode</DialogTitle>
        <DialogContent>
          <FormLabel>
            <RadioGroup value={evalOnly} onChange={handleRadioChange}>
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Full Analysis"
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Only Update Results"
              />
            </RadioGroup>
          </FormLabel>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary">
            Run Job
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setPending: PropTypes.func,
};

export default CreateConfirmationDialog;
