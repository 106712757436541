// App.jsx:
// Main component of the application. Contains the routing and global snackbar functions.

// Copyright HS Analysis GmbH, 2019
// Author: Viktor Eberhardt

// Framework imports
import "./css/global.css";
import React, { Component, Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// External packages
import { withSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

// HSA packages
import { PrivateRoute } from "./common/components";
import { Role } from "./common/utils";
import { TilesProvider } from "./viewer/contexts/TilesContext";
import { AIContextProvider } from "./aiViewer/AIContext";
import CustomDialog from "./common/components/CustomDialog";
import HomePage from "./home/HomePage";
import NavigationBar from "./common/components/NavigationBar";

import PersistentStorageProvider from "./viewer/contexts/PersistentStorageContext";
import ProjectHistoryProvider from "./viewer/contexts/ProjectHistoryContext";
import ProjectProvider from "./viewer/contexts/ProjectContext";
import ResultTabProvider from "./viewer/contexts/ResultTabContext";
import ScanProvider from "./scanViewer/contexts/ScanViewerContext";
import SpectraViewerProvider from "./spectraViewer/contexts/SpectraViewerContext";

//Components
const AboutPage = lazy(() => import("./about/AboutPage"));
const AdminPage = lazy(() => import("./admin/AdminPage"));
const AudioViewer = lazy(() => import("./audioViewer/AudioViewer"));
const AIViewer = lazy(() => import("./aiViewer/AIViewer"));
const CasesPage = lazy(() => import("./cases/CasesPage"));
const FileViewer = lazy(() => import("./fileViewer/FileViewer"));
const PredictionPage = lazy(() => import("./prediction/PredictionPage"));
const ProteomViewer = lazy(() => import("./proteomViewer/ProteomViewer"));
const Report = lazy(() => import("./home/Report"));
const ScanViewer = lazy(() => import("./scanViewer/ScanViewer"));
const SpectraViewer = lazy(() => import("./spectraViewer/SpectraViewer"));
const Viewer = lazy(() => import("./viewer/Viewer"));
const LoginPage = lazy(() => import("./account/LoginPage"));
const LicensingPage = lazy(() => import("./licensing/LicensingPage"));
const SettingsPage = lazy(() => import("./settings/SettingsPage"));
const UserHistoryPage = lazy(() => import("./userhistory/UserHistoryPage"));

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    height: 50,
  },
  appbarPlaceholder: theme.mixins.toolbar,
});

function SuspenseFallback() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 140px)"
    >
      <CircularProgress />
      <Typography variant="h6">Loading Page Data...</Typography>
    </Box>
  );
}

class App extends Component {
  static displayName = App.name;

  render() {
    const { classes } = this.props;

    /**
     * Adds a green success snackbar at bottom left of window.
     * @param {string} str Message to display.
     */
    window.showSuccessSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "success",
      });
    };

    /**
     * Adds an orange warning snackbar at bottom left of window.
     * @param {string} str Message to display.
     */
    window.showWarningSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "warning",
      });
    };

    /**
     * Adds a red error snackbar at bottom left of window.
     * @param {string} str Message to display.
     */
    window.showErrorSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "error",
      });
    };

    /**
     * Adds a black info snackbar at bottom left of window.
     * @param {string} str Message to display.
     * @param {function} action Action to perform once confirmed by user.
     * @param {string} action_msg Message displayed on the primary button.
     * @param {string} hide_msg Optional. Message displayed on dismiss button. If undefined, button will be hidden.
     */
    window.showActionSnackbar = (str, action, action_msg, hide_msg) => {
      // Close the old snackbar before showing the new one
      this.props.closeSnackbar();

      this.props.enqueueSnackbar(str, {
        action: (sbId) => (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                action();
                this.props.closeSnackbar(sbId);
              }}
            >
              {action_msg}
            </Button>
            {(hide_msg || hide_msg === 0) && hide_msg && (
              <Button
                sx={{ marginLeft: "3px" }}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => this.props.closeSnackbar(sbId)}
              >
                {hide_msg}
              </Button>
            )}
          </>
        ),
        persist: true,
      });
    };

    return (
      <div className={classes.root}>
        <div className={classes.appbarPlaceholder} />

        <Suspense fallback={<SuspenseFallback />}>
          <PrivateRoute
            path="/audio_view/:id"
            component={({ match }) => (
              <AudioViewer projectId={match.params.id} />
            )}
          />
          <PrivateRoute
            path="/ai_view"
            component={() => (
              <AIContextProvider>
                <AIViewer />
              </AIContextProvider>
            )}
          />
          <PrivateRoute path="/cases" component={() => <CasesPage />} />
          <PrivateRoute
            path="/proteome_view/:id"
            component={({ match }) => (
              <ProteomViewer id={match.params.id ? match.params.id : "001"} />
            )}
          />
          <PrivateRoute
            path="/file_view/:caseUrlId?"
            component={() => (
              <PersistentStorageProvider projectId="file_view">
                <TilesProvider>
                  <FileViewer />
                </TilesProvider>
              </PersistentStorageProvider>
            )}
          />
          <PrivateRoute
            path="/esr_view/:id"
            component={({ match }) => (
              <SpectraViewerProvider>
                <SpectraViewer id={match.params.id ? match.params.id : "001"} />
              </SpectraViewerProvider>
            )}
          />
          <PrivateRoute
            path="/scan_view"
            component={() => (
              <ScanProvider>
                <ScanViewer />
              </ScanProvider>
            )}
          />

          <PrivateRoute
            path="/prediction"
            component={() => <PredictionPage />}
          />
          <PrivateRoute
            path="/view/:id"
            component={({ match }) => (
              <PersistentStorageProvider
                projectId={match.params.id ? match.params.id : "001"}
              >
                <ProjectProvider>
                  <ProjectHistoryProvider historyDepth={20}>
                    <ResultTabProvider>
                      <TilesProvider>
                        <Viewer
                          id={match.params.id ? match.params.id : "001"}
                        />
                      </TilesProvider>
                    </ResultTabProvider>
                  </ProjectHistoryProvider>
                </ProjectProvider>
              </PersistentStorageProvider>
            )}
          />
          <PrivateRoute
            path="/report/:id"
            component={({ match }) => <Report id={match.params.id} />}
          />
          <PrivateRoute
            path="/merged_report/:id"
            component={({ match }) => <Report id={match.params.id} />}
          />
          <Route path="/login" component={() => <LoginPage />} />
          <Route path="/licensing" component={() => <LicensingPage />} />

          <PrivateRoute path="/settings" component={() => <SettingsPage />} />
          <PrivateRoute
            path="/userhistory"
            component={() => <UserHistoryPage />}
          />
          <PrivateRoute path="/about" component={() => <AboutPage />} />
          <PrivateRoute
            path="/admin"
            roles={[Role.Admin]}
            component={AdminPage}
          />
        </Suspense>

        <PrivateRoute exact path="/" component={() => <HomePage />} />
        <NavigationBar showDevButtons={false} />
        <CustomDialog />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(App));
