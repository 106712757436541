import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Button, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  root: {},
});

// This is commented out since the attepts to implement an autocomplete
// somehow failed. However, it took time to copy, so it stays for now.
// // Product per specifications
// const products = [
//   { label: "Bratwurst", abbr: "BW" },
//   { label: "Kochpökelware", abbr: "KO" },
//   { label: "Rohpökelware", abbr: "RO" },
//   { label: "Brühwurst", abbr: "BR" },
//   { label: "Verarbeitugnsfleisch", abbr: "VA" },
//   { label: "Separatorenfleisch", abbr: "SP" },
//   { label: "Hackfleisch roh", abbr: "HA" },
//   { label: "Hackfleischerz. gegart", abbr: "HG" },
//   { label: "Kochwurst", abbr: "KO" },
//   { label: "Rohwurst", abbr: "RO" },
//   { label: "Aspikwaren", abbr: "AS" },
//   { label: "Rohfleisch", abbr: "RF" },
//   { label: "Gegartes Fleisch", abbr: "GF" },
//   { label: "Kontrollen", abbr: "KO" },
//   { label: "Stufenkontrollen", abbr: "SK" },
//   { label: "LVU", abbr: "LV" },
//   { label: "Zusätze", abbr: "ZU" },
//   { label: "Fisch", abbr: "FI" },
//   { label: "Vegetarische Produkte", abbr: "VP" },
//   { label: "Sonstiges", abbr: "SO" },
// ];

// const products_2 = [
//   "BW",
//   "KO",
//   "RO",
//   "BR",
//   "VA",
//   "SP",
//   "HA",
//   "HG",
//   "AS",
//   "RF",
//   "GF",
//   "SK",
//   "LV",
//   "ZU",
//   "FI",
//   "VP",
//   "SO",
// ];

class EmptySlideCreation extends Component {
  // Allow format YYYY-XXZZZZZ-FF-TT-SSS-NN
  allowed_formats = [
    /^[0-9]{4}-[0-9]{7}-[0-9_]{2}-[A-Z]{2}-[A-Z0-9_]{3}-[0-9]{2}$/,
  ];

  constructor(props) {
    super(props);
    this.state = {
      emptyFile_name: "",
      ref_regex: React.createRef(),
      valid_regex: false,
    };
  }

  /**
   * Checks the input against all valid inputs and updates the status accordingly.
   * @param {string} input The currently typed value in the entry field.
   */
  checkFormat = (input) => {
    this.setState({
      valid_regex: this.allowed_formats.some((format) => {
        return format.test(input);
      }),
    });
    this.setState({ emptyFile_name: input });
  };

  /**
   * The histo classificaation module needs to work without an actual file.
   * Here, the interface is provided to add the relevant information to do so.
   * It mimics the filename structure YYYY-XXZZZZZ-FF-TT-SSS-NN
   * @returns empty file creation interface for histo modules
   */
  render() {
    return (
      <Grid
        id="EmptyFileParams"
        container
        style={{
          border: "2px solid rgb(218, 218, 218)",
          overflowY: "auto",
        }}
        alignItems="center"
      >
        <Grid item xs={8}>
          <TextField
            error={!this.state.valid_regex && !!this.state.emptyFile_name}
            name="Textfield"
            fullWidth
            inputProps={{ pattern: this.allowed_format }}
            inputRef={this.state.ref_regex}
            label="0000-0000000-00-AA-AAA-00"
            onChange={(e) => this.checkFormat(e.target.value)}
            type="text"
            variant="standard"
            value={this.state.emptyFile_name}
            // Enable adding files with "Enter" key.
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();

                if (this.state.valid_regex) {
                  // Add file with empty attribute to it
                  this.props.addFile(
                    e,
                    this.state.emptyFile_name.concat(" (empty)")
                  );
                  // Clear entry field for next entry
                  this.setState({ emptyFile_name: "" });
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={1} />
        {/* Add Button */}
        <Grid item xs={3}>
          <Button
            ref={this.state.ref_button}
            disabled={!this.state.valid_regex || !this.state.emptyFile_name}
            onClick={(event) => {
              // Add file with empty attribute to it
              this.props.addFile(
                event,
                this.state.emptyFile_name.concat(" (empty)")
              );
              // Clear entry field for next entry
              this.setState({ emptyFile_name: "" });
            }}
          >
            Add Empty Slide
          </Button>
        </Grid>
      </Grid>
    );
  }
}

EmptySlideCreation.propTypes = {
  addFile: PropTypes.func,
};

export default withStyles(styles)(EmptySlideCreation);
