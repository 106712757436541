// Copyright HS Analysis GmbH, 2023
// Author: Lukas Buess

// HSA imports
import AITrainingSettings from "./AITrainingSettings.jsx";
import { AITrainingInformation } from "./AITrainingInformation.jsx";
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  validateInstance,
} from "../utils/Utils";

/**
 * Information of an AI Training.
 * @param {AITrainingSettings} settings Settings used to train the AI model.
 * @param {AITrainingInformation} information Information about the AI training (e.g. progress, hardware, ...).
 */
export default class AITrainingDataContainer {
  constructor(
    settings = new AITrainingSettings(),
    information = new AITrainingInformation()
  ) {
    // Input validation
    validateInstance("settings", settings, AITrainingSettings);
    validateInstance("information", information, AITrainingInformation);

    // Set parameters
    this.settings = settings;
    this.information = information;
  }

  /**
   * Create a new AITrainingDataContainer from an object without needing to define all parameters individually.
   * Accepts camelCase and PascalCase properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AITrainingDataContainer constructor.
   * @param {object} obj An object containing all necessary properties of AITrainingDataContainer.
   * @returns {AITrainingDataContainer} New AITrainingDataContainer.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new AITrainingDataContainer(
      AITrainingSettings.fromObject(obj.settings),
      AITrainingInformation.fromObject(obj.information)
    );
  }
}
