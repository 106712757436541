// Utils.jsx
// This file contains utility functions for various purposes.

// Copyright HS Analysis GmbH, 2022
// Author: Valentin Haas

//#region Type checks

/**
 * Validate that a string follows the uuid format per RFC4122.
 * @param {string} inputString String to validate for uuid formatting.
 * @param {boolean} acceptNil Whether to accept NIL type UUID or not. Defaults to false
 * @returns {boolean} Whether the given string is a valid UUID.
 */
export function isUuid(inputString, acceptNil = false) {
  if (typeof inputString !== "string") return false;
  validateType("acceptNil", acceptNil, ValidationType.Bool);

  const uuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const uuid_w_nil =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  // Cheap length check
  if (inputString.length !== 36) return false;

  if (acceptNil) {
    return uuid_w_nil.test(inputString);
  } else {
    return uuid.test(inputString);
  }
}

/**
 * Check if a given string is a valid 3, 6, or 8 character hex color representation.
 * @param {string} inputString A string to test for valid color format.
 * @returns {bool} The given string is a valid hex color representation.
 */
export function isHexColor(inputString) {
  if (typeof inputString !== "string") return false;
  const hexColor = /^(#[\da-f]{3}|#[\da-f]{6}|#[\da-f]{8})$/i;
  return hexColor.test(inputString);
}

/**
 * Checks if a value is a plain object.
 * @param {number} input Object to test.
 * @returns {boolean} Whether the given value resembles an object.
 */
export function isObject(obj) {
  return obj && typeof obj === "object" && !Array.isArray(obj);
}

/**
 * Checks if a value is an integer value.
 * @param {number} input Number to test.
 * @returns {boolean} Whether the given value resembles an integer.
 */
export function isInt(input) {
  if (typeof input === "bigint") return true; // BigInts are always ints
  if (typeof input !== "number") return false;

  return (input | 0) === input;
}

/**All possible validation types for the validateType function. */
export const ValidationType = Object.freeze({
  String: 0,
  Int: 1,
  Number: 2,
  Bool: 3,
  Array: 4,
  Object: 5,
  Function: 6,
  Undefined: 7,
});

/**
 * Validates if a value has the expected type and throws an useful error if not.
 * @param {string} variableName Name of the variable to validate.
 * @param {any} value Value to validata.
 * @param {ValidationType} type Expected type.
 * @throws {TypeError} If the value is not of the expected type.
 * @returns {void}
 * @example validateType("myVar", myVar, ValidationType.String)
 */
export function validateType(variableName, value, type) {
  if (typeof variableName !== "string")
    throw TypeError(
      `variableName must be of type string, received ${typeof value}: ${value}`
    );
  validateInEnum("type", type, ValidationType);

  switch (type) {
    case ValidationType.String:
      if (typeof value !== "string")
        throw TypeError(
          `${variableName} must be of type string, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Int:
      if (!isInt(value))
        throw TypeError(
          `${variableName} must be of type int, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Number:
      if (typeof value !== "number")
        throw TypeError(
          `${variableName} must be of type float, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Bool:
      if (typeof value !== "boolean")
        throw TypeError(
          `${variableName} must be of type boolean, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Array:
      if (!Array.isArray(value))
        throw TypeError(
          `${variableName} must be of type array, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Object:
      if (!isObject(value))
        throw TypeError(
          `${variableName} must be of type object, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Function:
      if (typeof value !== "function")
        throw TypeError(
          `${variableName} must be of type function, received ${typeof value}: ${value}`
        );
      break;
    case ValidationType.Undefined:
      if (typeof value !== "undefined")
        throw TypeError(
          `${variableName} must be of type undefined, received ${typeof value}: ${value}`
        );
      break;
    default:
      throw TypeError(`Invalid type: ${type}`);
  }
}

/**
 * Validates if a value is of a given instance type and throws an useful error if not.
 * @param {string} variableName Name of the variable to validate.
 * @param {*} object Object to validate.
 * @param {function} instanceType Expected instance type.
 * @throws {TypeError} If the value is not of the expected instance type.
 * @returns {void}
 * @example validateInstance("myVar", myVar, MyClass)
 */
export function validateInstance(variableName, object, instanceType) {
  if (!(object instanceof instanceType))
    throw TypeError(
      `${variableName} must be of type ${
        instanceType.name
      }, received ${typeof object}: ${object}`
    );
}

/**
 * Validates if a value is a valid enum value and throws an useful error if not.
 * @param {string} variableName Name of the variable to validate.
 * @param {*} value Value to validate.
 * @param {object} enumType Enum type to validate against.
 * @throws {TypeError} If the value is not a valid enum value.
 * @returns {void}
 * @example validateInEnum("myVar", "value", MyEnum)
 */
export function validateInEnum(variableName, value, enumType) {
  if (!Object.values(enumType).includes(value))
    throw new TypeError(
      `${variableName} must be one of ${Object.values(
        enumType
      )}, received: ${value}`
    );
}

//#endregion

//#region Conversions
/**
 * Rounds a floating point number to specified decimals after the comma.
 * @param {number} num Number to round.
 * @param {int} decimals Decimals after the comma to round to. Defaults to 0.
 * @returns {number} Input rounded to specified decimal count.
 */
export function roundToDecimal(num, decimals = 0) {
  validateType("num", num, ValidationType.Number);

  if (!isInt(decimals) || decimals < 0) {
    throw TypeError(
      `decimals must be an integer >= 0, received ${typeof decimals}: ${decimals}`
    );
  }

  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) /
    Math.pow(10, decimals)
  );
}

/**
 * Convert a byte to its two character, hexadecimal uppercase representation.
 * @param {byte} input Byte (between 0 and 255) to convert to 2-letter hex value.
 * @returns {string} The input converted to a two-character hex string.
 */
export function byteToHex(input) {
  if (!isInt(input) || input < 0 || input > 255) {
    throw TypeError(
      `input must be an integer >= 0, <= 255, received ${typeof input}: ${input}`
    );
  }
  return ("0" + Number(input).toString(16)).slice(-2).toUpperCase();
}

/**
 * Convert 3 individual RGB values to a hex color string.
 * @param {int} r Red value from 0 to 255.
 * @param {int} g Green value from 0 to 255.
 * @param {int} b Blue value from 0 to 255.
 * @returns {string | Error} Hex color string in the format '#RRGGBB'.
 */
export const rgbToHex = (r, g, b) => {
  validateType("r", r, ValidationType.Int);
  validateType("g", g, ValidationType.Int);
  validateType("b", b, ValidationType.Int);

  const toHex = (c) => c.toString(16).padStart(2, "0");

  if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
    throw new RangeError("Invalid color component");
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
};

/**
 * Convert a rgba string to a hex string.
 * @param {string} rgbaString The rgba string to convert to hex string.
 * @returns {string} The hex string representation of the rgba string.
 */
export function rgbaToHex(rgbaString) {
  validateType("rgbaString", rgbaString, ValidationType.String);

  let r, g, b, alpha;
  const parts = rgbaString
    .substring(rgbaString.indexOf("rgba(") + 5, rgbaString.lastIndexOf(")"))
    .split(/,\s*/);

  if (parts.length === 4) {
    [r, g, b, alpha] = parts;
  } else if (parts.length === 3) {
    [r, g, b] = parts;
    alpha = "1";
  } else {
    throw TypeError(`Invalid rgba string: ${rgbaString}`);
  }

  // Convert alpha value to hex
  alpha = parseFloat(alpha);
  if (alpha < 0 || alpha > 1) {
    throw RangeError(`Alpha value must be between 0 and 1, received: ${alpha}`);
  }
  const alphaHex =
    alpha == 1
      ? ""
      : Math.round(alpha * 255)
          .toString(16)
          .padStart(2, "0")
          .toUpperCase();

  // Convert RGB values to hex
  r = parseInt(r);
  g = parseInt(g);
  b = parseInt(b);

  if (r < 0 || r > 255) {
    throw RangeError(`Red value must be between 0 and 255, received: ${r}`);
  }
  if (g < 0 || g > 255) {
    throw RangeError(`Green value must be between 0 and 255, received: ${g}`);
  }
  if (b < 0 || b > 255) {
    throw RangeError(`Blue value must be between 0 and 255, received: ${b}`);
  }
  const rgbHex = ((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase();

  return `#${rgbHex}${alphaHex}`;
}

export function hexToRgba(hex) {
  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;
  const result = regex.exec(hex);

  if (!result) {
    return null;
  }

  const [, r, g, b, a] = result;

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
    a: a ? roundToDecimal(parseInt(a, 16) / 255, 2) : 1,
  };
}

/**
 * Converts all PascalCase keys of an object to camelCase.
 * @param {object} o Arbitrary object to convert keys to camel case.
 * @returns {object} The object with camel case keys.
 */
export function convertPascalCaseKeysToCamelCase(o) {
  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (value !== null && typeof value === "object") {
        value = convertPascalCaseKeysToCamelCase(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (Object.prototype.hasOwnProperty.call(o, origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = convertPascalCaseKeysToCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

/**
 * Converts all snake_case keys of an object to camelCase.
 * @param {object} o Arbitrary object to convert keys to camel case.
 * @returns {object} The object with camel case keys.
 */
export function convertSnakeCaseKeysToCamelCase(o) {
  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (value !== null && typeof value === "object") {
        value = convertSnakeCaseKeysToCamelCase(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (Object.prototype.hasOwnProperty.call(o, origKey)) {
        newKey = (
          origKey.replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace("-", "").replace("_", "")
          ) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = convertSnakeCaseKeysToCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}
//#endregion

//#region Mathemathics
/**
 * Counts number of decimals of a number, up to a maximum of 16.
 * Will ignore trailing zeros.
 * @param {Number} x Number to count decimals of.
 * @returns {Number} Number of decimals.
 */
export function countDecimals(x) {
  validateType("x", x, ValidationType.Number);
  if (x === Math.floor(x)) return 0;
  return x.toString().split(".")[1].length ?? 0;
}

/**
 * Given an index and the total number of entries, return the log-scaled value.
 * @param {int} idx Index of all numbers to take logarithm of.
 * @param {int} total Total number of indicies.
 * @param {number} base Optional. Base of logarithm. Defaults to 10.
 * @returns
 */
export function logScale(idx, total, base = 10) {
  const logmax = logBase(total + 1, base);
  const exp = (logmax * idx) / total;
  return Math.round(Math.pow(base, exp) - 1);
}

/**
 * Takes the logarithm of a number to a given base.
 * @param {number} val Value to calculate logarithm for.
 * @param {number} base Base to take logarithem to.
 * @returns {number} log_base(val)
 */
export function logBase(val, base) {
  return Math.log(val) / Math.log(base);
}
//#endregion

//#region Strings
/**
 * Shortens a string to a maximum length and inserts "..." in the middle.
 * Will prefer the end of a string by one character over the start.
 * When the maximum length is less than 4, only the first character is shown.
 * @param {string} str The string to shorten.
 * @param {int} maxLength The maximum length of the string.
 * @returns {string} The shortened string.
 */
export function shortenString(str, maxLength) {
  validateType("str", str, ValidationType.String);
  validateType("maxLength", maxLength, ValidationType.Int);
  if (maxLength < 4)
    throw RangeError(`maxLength must be > 3, received: ${maxLength}`);

  // If the string is already shorter than the maximum length, return it as is
  if (str.length <= maxLength) return str;

  // If the maximum length is less than 5, only show the first character.
  if (maxLength == 4) return str.slice(0, 1) + "...";

  // Shorten the string and insert "..." in the middle
  const startIdx = Math.floor((maxLength - 3) / 2);
  const endIdx = -Math.ceil((maxLength - 3) / 2);
  return str.slice(0, startIdx) + "..." + str.slice(endIdx);
}
//#endregion

//#region Other
/**
 * Get the viewer defining url path from a project type in the format "/path/".
 * @param {string} projectType Name of the project type.
 * @returns {string} Viewer defining path for URL.
 */
export function viewerType(projectType) {
  validateType("projectType", projectType, ValidationType.String);
  projectType = projectType.toLowerCase();
  // Spectra Viewer
  if (
    projectType.includes("esrtraining") ||
    projectType.includes("esrevaluation")
  ) {
    return "/esr_view/";
  }
  // Proteome Viewer
  else if (projectType.includes("proteomeanalysis")) {
    return "/proteome_view/";
  }
  // Audio Viewer
  else if (projectType.includes("audioannotator")) {
    return "/audio_view/";
  }
  // Image Viewer
  else {
    return "/view/";
  }
}

/**
 * Download a json object as a file with a given name.
 * @param {JSON} jsonObject The JSON object to save.
 * @param {string} downloadName Optional. How the file will be called once downloaded.
 *                              Needs not end in .json. Defaults to export.json.
 */
export function downloadJsonObjectAsFile(
  jsonObject,
  downloadName = "export.json"
) {
  validateType("jsonObject", jsonObject, ValidationType.Object);
  validateType("downloadName", downloadName, ValidationType.String);
  if (downloadName.length <= 0)
    throw RangeError(
      `downloadName must not be empty, received: ${downloadName}`
    );

  let dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(jsonObject));

  let dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", downloadName);
  dlAnchorElem.click();
  dlAnchorElem.remove();
}

/**
 * Download an array as a file with a given name.
 * @param {array} arrayIn The array to save.
 * @param {string} downloadName Optional. How the file will be called once downloaded.
 *                              Needs not end in .json. Defaults to export.json.
 */
export function downloadArrayAsFile(arrayIn, downloadName = "export.json") {
  validateType("arrayIn", arrayIn, ValidationType.Array);
  validateType("downloadName", downloadName, ValidationType.String);
  if (downloadName.length <= 0)
    throw RangeError(
      `downloadName must not be empty, received: ${downloadName}`
    );

  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(arrayIn));

  const dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", downloadName);
  dlAnchorElem.click();
  dlAnchorElem.remove();
}

/**
 * Creates pop-up window to import a file via http input field.
 * @param {Array} acceptedFileTypes Strings of file endings allowed to import (including the dot, e.g. .json).
 * @returns {Promise} The files to import.
 */
export async function requestFileInput(acceptedFileTypes) {
  if (!Array.isArray(acceptedFileTypes))
    throw TypeError(
      `acceptedFileTypes must be an Array, received ${typeof acceptedFileTypes}: ${acceptedFileTypes}`
    );

  return new Promise((resolve) => {
    // Import window
    const inputElem = document.createElement("INPUT");
    inputElem.setAttribute("type", "file");
    inputElem.setAttribute("onChange", "file");
    inputElem.setAttribute("accept", acceptedFileTypes);
    inputElem.onchange = (e) => resolve(e.target.files);
    inputElem.click();
    inputElem.remove();
  });
}

/**
 * Shuffles array in place using Fisher-Yates algorithm.
 * @param {Array} a An array containing items to be shuffled.
 * @returns {Array} The shuffled array
 */
export function shuffle(a) {
  validateType("a", a, ValidationType.Array);

  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/**
 * if there were no new calls of this function for the delay time, the newest func will be triggered
 * @param {function} func callback function
 * @param {int} delay in ms
 * @returns
 */
export function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

/**
 * Determines if a given color is light or dark.
 * @param {string} color - The hex code of the color to check.
 * @returns {boolean} True if the color is light, false if it is dark.
 */
export function isColorLight(color) {
  // Convert the color to RGB
  const rgba = hexToRgba(color);

  // If the color is light because of transparence, return true imeediately
  if (rgba.a < 0.5) return true;

  // Calculate the luminance using the formula for relative luminance defined in WCAG 2.0
  const luminance = 0.2126 * rgba.r + 0.7152 * rgba.g + 0.0722 * rgba.b;

  // Set the threshold for light colors
  const threshold = 186;

  // Return true if the luminance is greater than the threshold for light colors
  return luminance > threshold;
}
//#endregion
