// Copyright HS Analysis GmbH, 2023
// Author: Valentin Haas

// Description: Standardized Roi Types for HSA KIT.
// For ROI documentation see https://hsa.atlassian.net/wiki/spaces/HSAKIT/pages/16116235173917/Annotations+Data+Structure

// Keep in sync with C# and js frontend:
// Source\HSA-KIT\Database\Model\RoiTypes.cs
// Source\HSA-KIT\ClientApp\src\common\components\RoiTypes.jsx
// Source\HSA-KIT\modules\hsa\core\models\roi_types.py

// External packages
import { v4 as uuidv4 } from "uuid";
import { bbox, bboxPolygon } from "@turf/turf";

// HSA imports
import Backend from "../utils/Backend";
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  downloadJsonObjectAsFile,
  isUuid,
  validateInEnum,
  validateInstance,
  validateType,
  ValidationType,
} from "../utils/Utils";
import { convertDateToShortIsoString } from "../../common/utils/Localization";
import { calcBoundingBoxFullObject } from "../../viewer/utils/PolygonUtil";
import Structure from "./Structure";

//#region DataType Enumerators
/**
 * Limits and describes possible modification statuses.
 */
export const ModificationStatus = Object.freeze({
  // Must be updated together with backend
  Saved: 0,
  Added: 1,
  Deleted: 2,
});

/**
 * Limits and describes annotation types.
 */
export const RoiType = Object.freeze({
  // Must be updated together with backend
  ImageRoi: 0,
  AudioRoi: 1,
});

/**
 * Limits and decribes possible image annotation types statuses.
 */
export const ImageAnnotationType = Object.freeze({
  // Must be updated together with backend
  Area: 0,
  Object: 1,
  Comments: 2,
});

/**
 * Implemented actions concerning annotations to be executed.
 */
export const AnnotationAction = Object.freeze({
  // Must be updated together with backend
  Save: 0,
  Export: 1,
  Import: 2,
  DeleteAll: 3,
});
//#endregion

//#region Roi Actions

/**
 * Save all passed rois to database.
 * Update rois in frontend based on response from backend.
 * @param {uuid} projectId Project id to save annotations for.
 * @param {object} file File to save annotations for.
 * @param {Structure} structure Structure to save annotations for.
 * @param {RoiType} annotationType Type of rois to save.
 * @param {array} annotations Annotations to save.
 * @param {function} callback Optional: Always execute after receiving and processing save response. Receives (array: newAnnotations).
 * @param {function} error Optional: Execute on error. Receives (object: error, array: newAnnotations).
 */
export function saveAnnotations(
  projectId,
  file,
  structure,
  annotationType,
  annotations,
  callback = () => {},
  error = () => {}
) {
  // Input validation
  if (!isUuid(projectId)) {
    throw TypeError(
      `projectId must be a valid UUID, received ${typeof projectId}: ${projectId}`
    );
  }

  validateType("file", file, ValidationType.Object);
  validateInstance("structure", structure, Structure);
  validateInEnum("annotationType", annotationType, RoiType);
  validateType("annotations", annotations, ValidationType.Array);

  // Stringify individual geojson regions
  if (annotationType === RoiType.ImageRoi) {
    annotations.forEach((a) => {
      a.coordinates = JSON.stringify(a.coordinates);
    });
  } else if (annotationType === RoiType.AudioRoi) {
    annotations.forEach((a) => {
      a.polyRep = JSON.stringify(a.polyRep);
    });
  }

  // Save annotations to database
  Backend.saveAnnotationsToDb(
    projectId,
    RoiType.AudioRoi,
    annotations,
    (data) => {
      // Read potentially updated roi ids.
      let oldIds = [];
      let newIds = [];

      data.roiUpdate.reassignedIds.forEach((idTuple) => {
        oldIds.push(idTuple[0]);
        newIds.push(idTuple[1]);
      });

      annotations = annotations.map((a) => {
        // Mirror potentially updated roi ids.
        if (oldIds.includes(a.id)) {
          a.id = newIds[oldIds.indexOf(a.id)];
        }

        // Everything was successfull -> Mark all annotations as saved.
        switch (a.modificationStatus) {
          case ModificationStatus.Saved:
            return a;

          case ModificationStatus.Added:
            a.modificationStatus = ModificationStatus.Saved;
            return a;

          case ModificationStatus.Deleted:
            // Exclude successfully deleted rois.
            break;

          default:
            break;
        }
      });

      // Remove undefineds
      annotations = annotations.filter((a) => a);
      console.debug(
        `Saved annotations for file "${file.fileName}", structure ${structure.label}: ${data.success}`
      );
      callback(annotations);
    },
    (err) => {
      // Some annotations failed to save to database.
      if (err.warning) {
        let oldIds = [];
        let newIds = [];

        // Read potentially updated roi ids.
        err.roiUpdate.reassignedIds.forEach((idTuple) => {
          oldIds.push(idTuple[0]);
          newIds.push(idTuple[1]);
        });

        annotations = annotations.map((a) => {
          // Mirror potentially updated roi ids.
          if (oldIds.includes(a.id)) {
            a.id = newIds[oldIds.indexOf(a.id)];
          }

          if (a.modificationStatus === ModificationStatus.Saved) {
            return a;
          }
          // Keep unsuccessfully added or unsuccessfully deleted rois unchanged
          else if (
            err.roiUpdate.failedToAdd.includes(a.id) ||
            err.roiUpdate.failedToDelete.includes(a.id)
          ) {
            return a;
          }
          // Set successfully added rois as saved.
          else if (a.modificationStatus === ModificationStatus.Added) {
            a.modificationStatus = ModificationStatus.Saved;
            return a;
          }
          // Exclude successfully deleted rois.
        });

        // Remove undefineds
        annotations = annotations.filter((a) => a);

        window.showWarningSnackbar(err.warning);
        console.debug(err);
        error(err, annotations);
      }
      // The save process went wrong somewhere
      else if (err.error) {
        window.showErrorSnackbar(
          `Error saving annotations of file "${file.fileName}", structure ${structure.label}:\n${err.error}`
        );
        error(err);
      }
      // Good luck
      else {
        window.showErrorSnackbar(
          `Unkonwn error saving annotations of file "${file.fileName}", structure ${structure.label}"`
        );
        error(err);
      }
    }
  );
}

/**
 * Export all annotations in frontend to .annhsa file.
 * Triggers download of the .annhsa file.
 * @param {string} username Name of the user exporting the annotations.
 * @param {object} project Origin project of the rois.
 * @param {object} file File corresponding to the rois.
 * @param {Structure} structure Structure corresponding to the rois.
 * @param {RoiType} annotationType Type of rois to save.
 * @param {array} annotations All annotations.
 * @return {object} Success status of export.
 */
export function exportAnnotations(
  username,
  project,
  file,
  structure,
  annotationType,
  annotations
) {
  // Input validation
  validateType("username", username, ValidationType.String);
  validateType("project", project, ValidationType.Object);
  validateType("file", file, ValidationType.Object);
  validateInstance("structure", structure, Structure);
  validateInEnum("annotationType", annotationType, RoiType);
  validateType("annotations", annotations, ValidationType.Array);

  try {
    // Export rois with meta info
    const timestamp = convertDateToShortIsoString(Date.now());
    downloadJsonObjectAsFile(
      {
        version: 1.0,
        timestamp: timestamp,
        user: username,
        project: project,
        file: {
          id: file.id,
          label: file.fileName,
        },
        structure: {
          id: structure.id,
          label: structure.label,
          isStructure: structure.isStructure,
        },
        annotationType: annotationType,
        annotations: annotations,
      },
      `${timestamp}_${project.name}_${file.fileName}_${structure.label}_(${annotations.length})_${username}.annhsa`
    );

    return {
      success: true,
      msg: `Successfully exported ${annotations.length} annotations of structure ${structure.label}`,
    };
  } catch (err) {
    return {
      success: false,
      msg: `Failed to export ${annotations.length} annotations of structure ${structure.label}`,
      error: err,
    };
  }
}

/**
 * Open a file selection window and import annotations from selected file to frontend.
 * Deletes all previous annotations.
 * @param {RoiType} annotationType The expected annotation type.
 * @param {array} existingAnnotations The previously exisiting annotations. Will all be set as to delete.
 * @param {object} file File corresponding to the rois.
 * @param {Structure} structure Structure corresponding to the rois.
 * @param {function} callback Always executes after loading and processing annotations. Receives (array: annotations).
 * @param {function} error Optional: Execute on error. Receives (object: error).
 */
export async function importAnnotations(
  annotationType,
  existingAnnotations,
  file,
  structure,
  callback,
  error = () => {}
) {
  // Input validation
  validateInEnum("annotationType", annotationType, RoiType);
  validateType(
    "existingAnnotations",
    existingAnnotations,
    ValidationType.Array
  );
  validateType("file", file, ValidationType.Object);
  validateInstance("structure", structure, Structure);

  // Warn about unsaved existing annotations
  const unsaved = existingAnnotations.filter(
    (a) => a.modificationStatus !== ModificationStatus.Saved
  ).length;
  if (unsaved > 0) {
    const continueImport = await window.openConfirmationDialog(
      "Unsaved annotations",
      `You have ${unsaved} unsaved modified ${
        unsaved === 1 ? "annotation" : "annotations"
      } in the structure "${structure.label}". 
      An import will irreversibly remove all unsaved annotations. 
      Import anyway?`
    );
    if (!continueImport) return;
  }

  // Import window
  const inputElem = document.createElement("INPUT");
  inputElem.setAttribute("type", "file");
  inputElem.setAttribute("onChange", "file");
  inputElem.setAttribute("accept", ".annhsa");
  inputElem.onchange = (e) => {
    // Analyse the incoming file, abort on no file.
    let files = e.target.files;
    if (files.length <= 0) return;

    let fr = new FileReader();
    fr.onload = async (e) => {
      try {
        const data = JSON.parse(e.target.result);
        // Data validation checks
        // TODO: Refactor into some kind of async .then functions
        if (data.annotationType !== annotationType) {
          throw TypeError(
            `Annotation type mismatch: was expecting "${annotationType}", imported file is of type "${data.annotationType}"`
          );
        }

        // Warn about file change
        if (data.file.id !== file.id && data.file.label !== file.fileName) {
          const continueImport = await window.openConfirmationDialog(
            "File Mismatch",
            `The annotations were created for a different file:\n"${data.file.label}". 
            You are attempting to import it to the file:\n"${file.fileName}". 
            An import will remove all existing annotations. 
            Import anyway?`
          );
          if (!continueImport) return;
        }

        // Warn about structure change
        if (
          data.structure.id !== structure.id &&
          data.structure.label !== structure.label
        ) {
          const continueImport = await window.openConfirmationDialog(
            "Structure Mismatch",
            `The annotations were created for a different structure: "${data.structure.label}". 
            You are attempting to import it to the structure: "${structure.label}". 
            An import will remove all existing annotations. 
            Import anyway?`
          );
          if (!continueImport) return;
        }

        // Warn about 0-length imported annotations
        if (data.annotations.length === 0) {
          const continueImport = await window.openConfirmationDialog(
            "No annotatations to import",
            `There are no annotations included in uploaded file. 
            An import will remove all existing annotations. 
            Import anyway?`
          );
          if (!continueImport) return;
        }

        const importedAnnotations = [];
        switch (annotationType) {
          case RoiType.ImageRoi:
            data.annotations.forEach((a) =>
              importedAnnotations.push(
                new ImageRoi(
                  uuidv4(),
                  file.id,
                  structure.id,
                  a.isAiAnnotated,
                  a.user,
                  ModificationStatus.Added,
                  a.z,
                  a.t,
                  a.coordinates,
                  a.annotationType,
                  a.minX,
                  a.minY,
                  a.maxX,
                  a.maxY
                )
              )
            );
            break;

          case RoiType.AudioRoi:
            data.annotations.forEach((a) =>
              importedAnnotations.push(
                new AudioRoi(
                  uuidv4(),
                  file.id,
                  structure.id,
                  a.isAiAnnotated,
                  a.user,
                  ModificationStatus.Added,
                  a.channels,
                  a.startTime,
                  a.endTime,
                  a.minFreq,
                  a.maxFreq,
                  a.polyRep
                )
              )
            );
            break;

          default:
            break;
        }

        // Set all existing annotations as deleted
        existingAnnotations.forEach(
          (anno) => (anno.modificationStatus = ModificationStatus.Deleted)
        );

        callback([...existingAnnotations, ...importedAnnotations]);
      } catch (err) {
        const errmsg = `Failed to import the annotations of type ${annotationType} from file ${files[0].name}:\n${err}`;
        console.debug(errmsg);
        error(errmsg);
      }
    };
    // Only accept the first element
    fr.readAsText(files.item(0));
  };
  inputElem.click();
  inputElem.remove();
}

/**
 * After a confirming prompt, delete all annotations of a structure in frontend.
 * @param {array} existingAnnotations The previously exisiting annotations. Will all be set as to delete.
 * @param {Structure} structure Structure corresponding to the rois.
 * @param {function} callback Save updated annotations after modfication. Receives (array: annotations).
 * @param {bool} forceDelete Optional. Delete all annotations with out asking user first. Defaults to false.
 */
export async function deleteAllAnnotations(
  existingAnnotations,
  structure,
  callback,
  forceDelete = false
) {
  // Input validation
  validateType(
    "existingAnnotations",
    existingAnnotations,
    ValidationType.Array
  );
  validateInstance("structure", structure, Structure);
  validateType("forceDelete", forceDelete, ValidationType.Bool);

  const continueDeletion =
    forceDelete ||
    (await window.openConfirmationDialog(
      "Delete all Annotations",
      `This action will delete all annotations of the structure "${structure.label}". Proceed?`
    ));
  if (!continueDeletion) return;

  const updatedAnnotations = existingAnnotations.map((a) => {
    a.modificationStatus = ModificationStatus.Deleted;
    return a;
  });

  callback(updatedAnnotations);
}

//#endregion

//#region General Roi definitions
/**
 * Basic information every type of region of interest (ROI) must contain.
 */
class Roi {
  // Must be updated together with backend
  /**
   * Create a ROI object with all parameters.
   * @param {Guid} id Id of the ROI.
   * @param {Guid} fileId Id of the associated scene.
   * @param {uint} structure Id of associated structure.
   * @param {bool} isAiAnnotated Whether or not this annotation is AI generated.
   * @param {ModificationStatus} modificationStatus Status of modification of any single annotation.
   * @param {Guid} user User that created this annotation.
   */
  constructor(
    id,
    fileId,
    structure,
    isAiAnnotated,
    modificationStatus = ModificationStatus.Added,
    user
  ) {
    // Input validation
    if (!isUuid(id)) {
      throw TypeError(
        `id must be a non-nil UUID, received ${typeof id}: ${id}`
      );
    }
    if (!isUuid(fileId)) {
      throw TypeError(
        `fileId must be a non-nil UUID, received ${typeof fileId}: ${fileId}`
      );
    }
    validateType("structure", structure, ValidationType.Int);
    if (structure < 0) {
      throw RangeError(
        `structure must be an integer >= 0, received ${typeof structure}: ${structure}`
      );
    }
    validateType("isAiAnnotated", isAiAnnotated, ValidationType.Bool);
    validateInEnum(
      "modificationStatus",
      modificationStatus,
      ModificationStatus
    );
    if (!isUuid(user)) {
      throw TypeError(
        `user must be a non-nil UUID, received ${typeof user}: ${user}`
      );
    }

    // Value assignment
    this.id = id;
    this.fileId = fileId;
    this.structure = structure;
    this.isAiAnnotated = isAiAnnotated;
    this.modificationStatus = modificationStatus;
    this.user = user;
  }
}
//#endregion

//#region ImageRois
/**
 * A ROI in an image or video, whose coordinates comply with the GeoJSON standard.
 */
export class ImageRoi extends Roi {
  // Must be updated together with backend
  /**
   * Create an ImageRoi object with all parameters.
   * @param {Guid} id Id of the ROI.
   * @param {Guid} fileId Id of the associated scene.
   * @param {Structure} structure Associated strucuture.
   * @param {bool} isAiAnnotated Whether or not this annotation is AI generated.
   * @param {ModificationStatus} modificationStatus Status of modification of any single annotation. 0: Saved to DB, 1: Added or modified, 2: Deleted
   * @param {Guid} user User that created this annotation.
   * @param {number} z Z-Layer in a z-Stack image.
   * @param {number} t Timeframe in a video.
   * @param {Array | string} coordinates The coordinates of the polygon, complies with the GeoJSON format. Can be passed as string, will be converted to json.
   * @param {ImageAnnotationType} annotationType Defines the type of annotations.
   * @param {number} minX Optional. Minimum X value of the polygon bounding box.
   * @param {number} minY Optional. Minimum Y value of the polygon bounding box.
   * @param {number} maxX Optional. Maximum X value of the polygon bounding box.
   * @param {number} maxY Optional. Maximum Y value of the polygon bounding box.
   */
  constructor(
    id,
    fileId,
    structure,
    isAiAnnotated,
    user,
    modificationStatus,
    z,
    t,
    coordinates,
    annotationType,
    minX,
    minY,
    maxX,
    maxY
  ) {
    super(id, fileId, structure, isAiAnnotated, modificationStatus, user);

    // Input validation
    validateType("z", z, ValidationType.Int);
    if (z < 0) {
      throw RangeError(`z must be an integer >= 0, received ${typeof z}: ${z}`);
    }

    validateType("t", t, ValidationType.Int);
    if (t < 0) {
      throw RangeError(`t must be an integer >= 0, received ${typeof t}: ${t}`);
    }

    if (!Array.isArray(coordinates) && typeof coordinates !== "string") {
      throw TypeError(
        `Invalid coordinates, received ${typeof coordinates}: ${coordinates}`
      );
    }

    validateInEnum("annotationType", annotationType, ImageAnnotationType);
    validateType("minX", minX, ValidationType.Number);
    validateType("minY", minY, ValidationType.Number);
    validateType("maxX", maxX, ValidationType.Number);
    validateType("maxY", maxY, ValidationType.Number);

    // Value assignment
    this.z = z;
    this.t = t;
    this.coordinates =
      typeof coordinates === "string" ? JSON.parse(coordinates) : coordinates;
    this.annotationType = annotationType;

    // Should the values not be passed on, they must be calculated.
    if (minX && minY && maxX && maxY) {
      this.minX = minX;
      this.minY = minY;
      this.maxX = maxX;
      this.maxY = maxY;
    } else {
      // Calculate bounding box
      let rect = calcBoundingBoxFullObject(this.coordinates);
      // Assuming coordinate origin in top left corner
      // . -- x -->
      // |
      // y
      // |
      // V
      this.minX = rect.left;
      this.minY = rect.top;
      this.maxX = rect.right;
      this.maxY = rect.bottom;
    }
  }

  /**
   * Returns an item that can be added to rTree or rBush.
   * Prevents the need to manually define such a tree item every time.
   * @returns Treeitem with bounding box and reference to roi.
   */
  getTreeItem() {
    return {
      minX: this.minX,
      minY: this.minY,
      maxX: this.maxX,
      maxY: this.maxY,
      roi: this,
    };
  }

  /**
   * Create a new ImageRoi from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per ImageRoi constructor.
   * @param {object} obj An object containing all necessary properties of a ImageRoi.
   * @returns {ImageRoi} A new ImageRoi.
   */
  static fromObject = (obj) => {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new ImageRoi(
      obj.id,
      obj.fileId,
      obj.structure,
      obj.isAiAnnotated,
      obj.user,
      obj.modificationStatus,
      obj.z,
      obj.t,
      obj.coordinates,
      obj.annotationType,
      obj.minX,
      obj.minY,
      obj.maxX,
      obj.maxY
    );
  };
}
//#endregion

//#region AudioRois
export class AudioRoi extends Roi {
  // Must be updated together with backend
  /**
   * Create a AudioRoi object with all parameters.
   * @param {Guid} id Id of the ROI.
   * @param {Guid} fileId Id of the associated scene.
   * @param {Structure} structure Associated structure.
   * @param {bool} isAiAnnotated Whether or not this annotation is AI generated.
   * @param {Guid} user User that created this annotation.
   * @param {ModificationStatus} modificationStatus Status of modification of any single annotation.
   * @param {array} channels All channels the annotation is present in.
   * @param {number} startTime Timestamp of annotation beginning in seconds.
   * @param {number} endTime Timestamp of annotation end in seconds.
   * @param {number} minFreq Minimum frequency of annotation in Hertz.
   * @param {number} maxFreq Maximum frequency of annotation in Hertz.
   * @param {array} polyRep Optional. Polygon representation of the annotation. If not passed, will be calculated from startTime, endTime, minFreq and maxFreq. Defaults to null.
   */
  constructor(
    id,
    fileId,
    structure,
    isAiAnnotated,
    user,
    modificationStatus,
    channels,
    startTime,
    endTime,
    minFreq,
    maxFreq,
    polyRep = null
  ) {
    super(id, fileId, structure, isAiAnnotated, modificationStatus, user);

    // Input validation
    validateType("channels", channels, ValidationType.Array);
    channels.forEach((c) => {
      validateType("channel", c, ValidationType.Int);
    });

    validateType("startTime", startTime, ValidationType.Number);
    if (startTime < 0) {
      throw RangeError(
        `startTime must be a number >= 0, received ${typeof startTime}: ${startTime}`
      );
    }

    validateType("endTime", endTime, ValidationType.Number);
    if (endTime < 0) {
      throw RangeError(
        `endTime must be a number >= 0, received ${typeof endTime}: ${endTime}`
      );
    }

    validateType("minFreq", minFreq, ValidationType.Number);
    if (minFreq < 0) {
      throw RangeError(
        `minFreq must be a number >= 0, received ${typeof minFreq}: ${minFreq}`
      );
    }

    validateType("maxFreq", maxFreq, ValidationType.Number);
    if (maxFreq < 0) {
      throw RangeError(
        `maxFreq must be a number >= 0, received ${typeof maxFreq}: ${maxFreq}`
      );
    }

    // Value assignments
    this.channels = channels;

    // Parse stringified polyRep
    while (typeof polyRep === "string") {
      polyRep = JSON.parse(polyRep);
    }

    // If polyRep is passed, use it, otherwise calculate it from startTime, endTime, minFreq and maxFreq
    if (polyRep != null) {
      this.polyRep = polyRep;
    } else {
      if (
        startTime === undefined ||
        endTime === undefined ||
        minFreq === undefined ||
        maxFreq === undefined
      ) {
        throw RangeError(
          `startTime, endTime, minFreq and maxFreq must be defined if polyRep is not passed.`
        );
      }

      // Ensure startTime comes before endtime
      if (startTime < endTime) {
        this.startTime = startTime;
        this.endTime = endTime;
      } else if (startTime > endTime) {
        // Switch times
        this.startTime = endTime;
        this.endTime = startTime;
      } else {
        // startTime === endTime
        throw RangeError("startTime and endTime must not be identical.");
      }

      // Ensure minFreq comes before maxFreq
      if (minFreq < maxFreq) {
        this.minFreq = minFreq;
        this.maxFreq = maxFreq;
      } else if (minFreq > maxFreq) {
        // Switch frequencies
        this.minFreq = maxFreq;
        this.maxFreq = minFreq;
      } else {
        // minFreq === maxFreq
        throw RangeError("minFreq and maxFreq must not be identical.");
      }

      // Calculate polygon representation
      this.polyRep = bboxPolygon([
        this.startTime,
        this.minFreq,
        this.endTime,
        this.maxFreq,
      ]);
    }

    // Ensure that polyRep is used as basis for startTime and endTime, minFreq and maxFreq
    // Calculate bounding box
    const box = bbox(this.polyRep);
    // Assuming coordinate origin in top left corner
    // . -- x -->
    // |
    // y
    // |
    // V
    this.startTime = box[0];
    this.minFreq = box[1];
    this.endTime = box[2];
    this.maxFreq = box[3];
  }

  /**
   * Create a new AudioRoi from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AudioRoi constructor.
   * @param {object} obj An object containing all necessary properties of a AudioRoi.
   * @returns {AudioRoi} A new AudioRoi.
   */
  static fromObject = (obj) => {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new AudioRoi(
      obj.id,
      obj.fileId,
      obj.structure,
      obj.isAiAnnotated,
      obj.user,
      obj.modificationStatus,
      obj.channels,
      obj.startTime,
      obj.endTime,
      obj.minFreq,
      obj.maxFreq,
      obj.polyRep
    );
  };
}

//#endregion
