import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ImageListItem, ImageList, ImageListItemBar } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  projectTilesList: {
    maxHeight: 437,
    maxWidth: 750,
    padding: 3,
  },

  projectTile: {
    cursor: "pointer",
    padding: 5,
    overflow: "hidden",
    border: "1px solid black",
    "&:hover": {
      background: "#AAA",
    },
  },
  projectTileSelected: {
    background: "#1BA1E2!important",
  },

  expiredMarker: {
    color: "red",
    border: "2px solid red",
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 1,
    background: "rgba(255,255,255,0.9)",
    padding: "2px 5px 5px 5px",
  },
  expiringMarker: {
    color: "darkorange",
    border: "2px solid darkorange",
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
    background: "rgba(255,255,255,0.9)",
    padding: "2px 5px 5px 5px",
    lineHeight: "16px",
  },
});

const ProjectTypeList = ({
  projectType,
  onProjectType,
  availableTypes,
  searchText,
}) => {
  const classes = useStyles();
  return (
    <ImageList
      id={"ImageList"}
      rowHeight={215}
      className={classes.projectTilesList}
      spacing={8}
      cols={3}
    >
      {availableTypes
        .filter((row) => {
          let searchContent = row.label.toLowerCase();
          if (row.projectProperties.Hidden) return false;
          let toSearch = searchText.toLowerCase();
          return searchContent.includes(toSearch);
        })
        .map((availableType) => (
          <ImageListItem
            key={availableType.name}
            onClick={() => onProjectType(availableType)}
            className={classNames(
              classes.projectTile,
              projectType === availableType.name && classes.projectTileSelected
            )}
          >
            {availableType.projectProperties.WIP && (
              <div className={classes.expiringMarker}>WIP</div>
            )}

            {availableType.isValid === false &&
              availableType.expirationDate && (
                <div className={classes.expiredMarker}>
                  Expired on{" "}
                  {availableType.expirationDate.replace("T00:00:00", "")}!
                </div>
              )}
            {availableType.isValid && availableType.expiresInDays < 32 && (
              <div className={classes.expiringMarker}>
                Expires in {availableType.expiresInDays} days!
              </div>
            )}

            <img
              src={`data:image/jpeg;base64,${availableType.image}`}
              alt={availableType.name}
              className={classes.tileImage}
            />

            <ImageListItemBar
              className={classes.titleBar}
              title={availableType.label}
            />
          </ImageListItem>
        ))}
    </ImageList>
  );
};

ProjectTypeList.propTypes = {
  projectType: PropTypes.string.isRequired,
  onProjectType: PropTypes.func.isRequired,
  availableTypes: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default ProjectTypeList;
