// AIContext.jsx
// Contains all variables for the AI Viewer page that need to be accessible throughout its entirety.

// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

// Framework imports
import React from "react";
import { PropTypes } from "prop-types";

// HSA imports
import AITrainingDataContainer from "../common/components/AITrainingDataContainer";
import AIModel2ProjectTypeMapping from "./components/AIModel2ProjectTypeMapping";
import Backend from "../common/utils/Backend";
import Structure from "../common/components/Structure";
import { ImageInferenceParameters } from "../common/components/IAMConfig";
import { TargetDataFormat } from "../common/components/AITrainingSettings";

const AIContext = React.createContext();
export default AIContext;

const AIContextProvider = ({ children }) => {
  //#region State variables
  const [hasChanges, setHasChanges] = React.useState(false);
  const [initError, setInitError] = React.useState(null);
  const [initializing, setInitializing] = React.useState(true);
  const [modelFilterValue, setModelFilterValue] = React.useState("");
  const [projectTypes, setProjectTypes] = React.useState([]);
  const [projectTypeSettingList, setProjectTypeSettingList] = React.useState(
    []
  );
  const [projectTypeStructures, setProjectTypeStructures] = React.useState([]);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [selectedModelName, setSelectedModelName] = React.useState("");
  const [selectedProjectType, setSelectedProjectType] = React.useState({});
  const [selectedStructuresMappingIdx, setSelectedStructuresMappingIdx] =
    React.useState(0);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [structuresMapping, setStructuresMapping] = React.useState([]);
  const [trainedModels, setTrainedModels] = React.useState([]);
  //#endregion

  //#region Effect hooks
  React.useEffect(() => {
    initialize();
  }, []);

  React.useEffect(() => {
    if (!initializing) return;
    initialize();
  }, [initializing]);

  React.useEffect(() => {
    if (selectedProjectType.name === undefined) return;
    setProjectTypeStructures([]);
    Backend.loadViewerConfig(selectedProjectType.name, (viewerConfig) => {
      const newProjectTypeStructures = viewerConfig.project.structures.map(
        (structure) => {
          if (structure.parentId === 0) {
            structure.parentId = null;
          }
          return Structure.fromObject(structure);
        }
      );
      setProjectTypeStructures(newProjectTypeStructures);
    });
  }, [selectedProjectType]);

  React.useEffect(() => {
    if (!selectedModel) return;
    Backend.loadAvailableProjectTypes(false).then((projectTypes) => {
      const selectedProjectType = projectTypes.find(
        (item) => item.name === selectedModel.settings.projectType
      );
      if (selectedProjectType) {
        updateSelectedProjectType(selectedProjectType);
      }
    });
    Backend.readProjectTypeMappingForAIModel(
      selectedModel.settings.metaData.name
    ).then((mappingArray) => {
      initMapping(mappingArray);
    });
    setSelectedStructuresMappingIdx(0);
    setHasChanges(false);
  }, [selectedModel]);

  //#endregion

  //#region Helper functions

  /**
   * Initializes the AI context by loading the trained models and project types from the backend.
   */
  const initialize = () => {
    Backend.getModelMetadata("hsa_models", false)
      .then((res) => {
        setTrainedModels(res.newModels);
        setInitializing(false);
        setInitError(null);
      })
      .catch((error) => {
        console.error("Error in loading models:\n", error);
        setInitializing(false);
        setInitError(error);
      });
    Backend.loadAvailableProjectTypes(false).then((projectTypes) => {
      setProjectTypes(projectTypes);
    });
  };

  /**
   * Fetches the trained models from the backend and overwrites the current models.
   */
  const updateModels = () => {
    Backend.getModelMetadata("hsa_models", false)
      .then((res) => {
        setTrainedModels(res.newModels);
        setInitError(null);
      })
      .catch((error) => {
        console.error("Error in loading models:\n", error);
        setInitError(error);
      });
  };

  const AIModels = [];
  const nTrainedModels = trainedModels.length;
  if (nTrainedModels > 0) {
    for (let i = 0; i < nTrainedModels; i++) {
      const model = trainedModels[i];
      if (model.settings.trainingParameters.epochs === 0) {
        console.error(
          "Model has 0 epochs, skipping model",
          model.settings.metaData.name
        );
        continue;
      }

      // initialize AIModelDataContainer
      const AIModelDataContainer = AITrainingDataContainer.fromObject(model);

      AIModels.push(AIModelDataContainer);
    }

    AIModels.sort((a, b) => {
      return (
        Date.parse(b.information.creationDate) -
        Date.parse(a.information.creationDate)
      );
    });
  }

  const createDefaultModelMapping = (model) => {
    const mappingObject = {
      projectType: model.settings.projectType,
      aiModel: model.settings.metaData.name,
      targetDataFormat: TargetDataFormat.Image,
      structureMappings: model.settings.structures.map((modelStructure) => {
        return {
          oldId: modelStructure.id,
          newId: modelStructure.id,
        };
      }),
      inferenceParameters: new ImageInferenceParameters(0.15),
      isActive: false,
      outerStructureId: 1,
    };
    return mappingObject;
  };

  const initMapping = (mappingArray) => {
    const newStructureMapping = mappingArray.map((item) => {
      return AIModel2ProjectTypeMapping.fromObject(item);
    });
    if (
      !mappingArray.find(
        (item) => item.aiModel === selectedModel.settings.metaData.name
      )
    ) {
      const mappingObject = createDefaultModelMapping(selectedModel);
      const modelStructureMapping =
        AIModel2ProjectTypeMapping.fromObject(mappingObject);
      newStructureMapping.push(modelStructureMapping);
    }
    setStructuresMapping(newStructureMapping);
  };

  const updateSelectedProjectType = (projectType) => {
    const index = structuresMapping.findIndex(
      (item) => projectType.name === item.projectType
    );
    if (index !== -1) {
      setSelectedStructuresMappingIdx(index);
    }
    setSelectedProjectType(projectType);
  };

  const saveCurrentModelSettings = () => {
    Backend.writeProjectTypeMappingForAIModel(
      selectedModel.settings.metaData.name,
      structuresMapping
    ).then((response) => {
      if (response.ok) {
        window.showSuccessSnackbar("Model settings saved successfully");
        setHasChanges(false);
      } else {
        window.openErrorDialog(
          "Error in saving model settings" + response.error
        );
      }
    });
  };

  const addMapping = (projectType) => {
    if (
      structuresMapping.find((item) => item.projectType === projectType.name)
    ) {
      window.showWarningSnackbar(
        "Mapping already exists for this project type"
      );
      return;
    }
    const mappingObject = {
      projectType: projectType.name,
      aiModel: selectedModel.settings.metaData.name,
      targetDataFormat: TargetDataFormat.Image,
      inferenceParameters: new ImageInferenceParameters(0.15),
      structureMappings: selectedModel.settings.structures.map(
        (modelStructure) => {
          return {
            oldId: modelStructure.id,
            newId: null,
          };
        }
      ),
      outerStructureId: 1,
      isActive: false,
    };
    const newMapping = AIModel2ProjectTypeMapping.fromObject(mappingObject);
    const mappingArray = [...structuresMapping];
    mappingArray.push(newMapping);
    setStructuresMapping(mappingArray);
    setSelectedStructuresMappingIdx(mappingArray.length - 1);
    setSelectedProjectType(projectType);
  };

  const removeMapping = (index) => {
    let newItems = [...structuresMapping];
    newItems.splice(index, 1);
    setStructuresMapping(newItems);
    setHasChanges(true);
    if (
      selectedStructuresMappingIdx === index ||
      selectedStructuresMappingIdx > newItems.length - 1
    ) {
      handleSelectProjectTypeIdx(0);
    }
  };

  const handleSelectProjectTypeIdx = (index) => {
    setSelectedStructuresMappingIdx(index);
    const selectedMapping = structuresMapping[index];
    const projectType = projectTypes.find(
      (item) => item.name === selectedMapping.projectType
    );
    setSelectedProjectType(projectType);
  };

  const updateStructuresMappingWithIdx = (mappingItem, idx) => {
    const newStructuresMapping = [...structuresMapping];
    newStructuresMapping[idx] = mappingItem;
    setStructuresMapping(newStructuresMapping);
    setHasChanges(true);
  };
  //#endregion

  return (
    <AIContext.Provider
      value={{
        addMapping,
        AIModels,
        handleSelectProjectTypeIdx,
        hasChanges,
        initError,
        initializing,
        setInitializing,
        modelFilterValue,
        setModelFilterValue,
        projectTypes,
        projectTypeSettingList,
        setProjectTypeSettingList,
        projectTypeStructures,
        removeMapping,
        saveCurrentModelSettings,
        selectedModel,
        setSelectedModel,
        selectedModelName,
        setSelectedModelName,
        selectedProjectType,
        setSelectedProjectType,
        updateSelectedProjectType,
        selectedStructuresMappingIdx,
        setSelectedStructuresMappingIdx,
        updateStructuresMappingWithIdx,
        selectedTab,
        setSelectedTab,
        structuresMapping,
        trainedModels,
        updateModels,
      }}
    >
      {children}
    </AIContext.Provider>
  );
};

AIContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AIContextProvider };
