// FileFormatUtils.js
// This file contains a class with utility constants and functions to handle file formats and lists of directories and files.

// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

class FileFormatUtils {
  /**
   * The supportedFormats object defines the supported file formats for different categories.
   * Each category is represented by a key, and the corresponding value is an array of file extensions.
   *
   * @type {Object}
   * @property {string[]} text - The supported file extensions for text files.
   * @property {string[]} ESR - The supported file extensions for ESR files.
   * @property {string[]} Audio - The supported file extensions for audio files.
   * @property {string[]} Viewer - The supported file extensions for viewer files.
   */
  static supportedFormats = {
    text: ["txt"],
    ESR: ["csv"],
    Audio: ["mp3", "wav"],
    Viewer: [
      "avi",
      "bmp",
      "czi",
      "dm3",
      "gif",
      "hsasld",
      "jpeg",
      "jpg",
      "lif",
      "mp4",
      "mrxs",
      "ndpi",
      "png",
      "scn",
      "svs",
      "svslide",
      "tif",
      "tiff",
      // The following formats are not supported on linux systems
      "isyntax",
      "nd2",
      "zvi",
    ],
  };

  /**
   * Filters the given array of entities to only include files with supported extensions for viewing.
   * Directories with a corresponding .mrxs file are excluded.
   *
   * @param {Array} entities - The array of entities to filter.
   * @returns {Array} - The filtered array of entities.
   */
  static filterViewerEntities(entities) {
    return entities.filter((entity) => {
      if (entity.type === "file") {
        // get the extension from entity.path
        const entityExtension = entity.path.split(".").pop();
        // check if the extension is in supportedFormats.Viewer
        return FileFormatUtils.supportedFormats.Viewer.includes(
          entityExtension
        );
      }
      if (entity.type === "directory") {
        return !entities.some(
          (element) => element.path === `${entity.path}.mrxs`
        );
      }
      return false;
    });
  }

  /**
   * Sorts an array of entities by type and name.
   * directories come before files, and entities are sorted by name.
   *
   * @param {Array} entities - The array of entities to be sorted.
   * @param {string} sortBy - The criteria to sort by (e.g., "date").
   * @param {boolean} sortReverse - Whether to reverse the sorted array.
   * @returns {Array} - The sorted array of entities.
   */
  static sortEntitiesByTypeAndName(entities, sortBy = "", sortReverse = false) {
    const sortedEntities = entities.sort((a, b) => {
      // directory before file (directories have no creationTime)
      if (a.type === "directory" && b.type !== "directory") return -1;
      if (a.type !== "directory" && b.type === "directory") return 1;

      if (
        sortBy === "date" &&
        a.creationTime &&
        b.creationTime &&
        a.creationTime !== b.creationTime
      ) {
        return a.creationTime > b.creationTime ? 1 : -1;
      }

      // Function to find the common prefix of two strings
      const findCommonPrefix = (str1, str2) => {
        let i = 0;
        while (i < str1.length && i < str2.length && str1[i] === str2[i]) {
          i++;
        }
        return str1.substring(0, i);
      };

      // Remove the common prefix from both paths
      const commonPrefix = findCommonPrefix(a.path, b.path);
      const pathA = a.path.substring(commonPrefix.length);
      const pathB = b.path.substring(commonPrefix.length);

      // Function to extract numeric parts from a string
      const extractNumbers = (str) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : null;
      };

      const numA = extractNumbers(pathA);
      const numB = extractNumbers(pathB);

      if (numA !== null && numB !== null) {
        // Compare numeric values
        return numA - numB;
      }

      // Fallback to comparing paths
      return a.path.localeCompare(b.path);
    });

    // Reverse the sorted array if sortReverse is true
    if (sortReverse) {
      sortedEntities.reverse();
    }

    return sortedEntities;
  }
}

export default FileFormatUtils;
