// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// HSA imports
import { isInt, isUuid, validateInstance } from "../utils/Utils";
import { Parameter } from "./InstantAnalysisModule";
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  validateType,
  ValidationType,
} from "../utils/Utils";

//#region AI Inference Parameters

/**
 * General class for all dataset specific parameters of an AI Training.
 */
export class InferenceParameters {
  constructor(confidenceThreshold = 0.15) {
    // Input validation
    validateType(
      "confidenceThreshold",
      confidenceThreshold,
      ValidationType.Number
    );

    // Set parameters
    this.confidenceThreshold = confidenceThreshold;
  }

  /**
   * Converts an object to an InferenceParameters.
   * @param {Object} obj An object with the properties of the InferenceParameters.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);
    return new InferenceParameters(obj.confidenceThreshold);
  }
}

/**
 * All model specific parameters of an image model AI Training.
 * @param {number} confidenceThreshold Confidence threshold for the inference.
 * @param {number} minObjectSize Optional. Minimum object size as area for the inference. Default: null
 * @param {number} maxObjectSize Optional. Maximum object size as area for the inference. Default: null
 * @param {number} minIntensity Optional. Minimum intensity of the object for the inference. Default: null
 * @param {number} maxIntensity Optional. Maximum intensity of the object for the inference. Default: null
 */
export class ImageInferenceParameters extends InferenceParameters {
  constructor({
    confidenceThreshold,
    minObjectSize = null,
    maxObjectSize = null,
    minIntensity = null,
    maxIntensity = null,
  } = {}) {
    super(confidenceThreshold);

    // Input validation
    if (minObjectSize !== null)
      validateType("minObjectSize", minObjectSize, ValidationType.Number);
    if (maxObjectSize !== null)
      validateType("maxObjectSize", maxObjectSize, ValidationType.Number);
    if (minIntensity !== null)
      validateType("minIntensity", minIntensity, ValidationType.Number);
    if (maxIntensity !== null)
      validateType("maxIntensity", maxIntensity, ValidationType.Number);

    this.minObjectSize = minObjectSize;
    this.maxObjectSize = maxObjectSize;
    this.minIntensity = minIntensity;
    this.maxIntensity = maxIntensity;
  }

  /**
   * Converts an object to an ImageInferenceParameters.
   * @param {Object} obj An object with the properties of the ImageInferenceParameters.
   * @returns {ImageInferenceParameters} The converted ImageInferenceParameters.
   **/
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);
    return new ImageInferenceParameters({
      confidence: obj.confidence,
      minimumObjectSize: obj.minimumObjectSize,
      maximumObjectSize: obj.maximumObjectSize,
    });
  }
}

/**
 * All model specific parameters of an audio model AI Training.
 * @param {number} confidence Confidence threshold for the inference.
 * @param {number} minTimeSeconds Optional. Minimum time in seconds for the inference. Default: null
 * @param {number} maxTimeSeconds Optional. Maximum time in seconds for the inference. Default: null
 * @param {number} minFrequencyHz Optional. Minimum frequency in Hz for the inference. Default: null
 * @param {number} maxFrequencyHz Optional. Maximum frequency in Hz for the inference. Default: null
 */
export class AudioInferenceParameters extends InferenceParameters {
  constructor(
    confidence,
    minTimeSeconds = null,
    maxTimeSeconds = null,
    minFrequencyHz = null,
    maxFrequencyHz = null
  ) {
    super(confidence);

    // Input validation
    if (minTimeSeconds !== null)
      validateType("minTimeSeconds", minTimeSeconds, ValidationType.Number);
    if (maxTimeSeconds !== null)
      validateType("maxTimeSeconds", maxTimeSeconds, ValidationType.Number);
    if (minFrequencyHz !== null)
      validateType("minFrequencyHz", minFrequencyHz, ValidationType.Number);
    if (maxFrequencyHz !== null)
      validateType("maxFrequencyHz", maxFrequencyHz, ValidationType.Number);

    // Set parameters
    this.minTimeSeconds = minTimeSeconds;
    this.maxTimeSeconds = maxTimeSeconds;
    this.minFrequencyHz = minFrequencyHz;
    this.maxFrequencyHz = maxFrequencyHz;
  }

  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);
    return new AudioInferenceParameters(
      obj.confidence,
      obj.minTimeSeconds,
      obj.maxTimeSeconds,
      obj.minFrequencyHz,
      obj.maxFrequencyHz
    );
  }
}

//#endregion

//#region Search Areas

/**
 * Parent class for search areas.
 * Abstract class, should not be used directly.
 */
class SearchArea {}

/**
 * Search area for image-based analysis.
 * @param {number} x Origin of a rectangle in x direction of image coordinates. Must be a positive integer.
 * @param {number} y Origin of a rectangle in y direction of image coordinates. Must be a positive integer.
 * @param {number} z First z-Layer in a z-Stack image. Must be a positive integer. Default: 0
 * @param {number} t First timepoint in a time series image. Must be a positive integer. Default: 0
 * @param {number} w Width of the search area in x direction of image coordinates. Must be a positive integer.
 * @param {number} h Height of the search area in y direction of image coordinates. Must be a positive integer.
 * @param {number} d Depth of the search area in z direction of image coordinates. Must be a positive integer. Default: 1
 * @param {number} l Length of the search area in amount of frames of a time series image. Must be a positive integer. Default: 1
 */
export class ImageSearchArea extends SearchArea {
  constructor(x, y, z = 0, t = 0, w, h, d = 1, l = 1) {
    super();

    validateType("x", x, ValidationType.Int);
    validateType("y", y, ValidationType.Int);
    validateType("z", z, ValidationType.Int);
    validateType("t", t, ValidationType.Int);
    validateType("w", w, ValidationType.Int);
    validateType("h", h, ValidationType.Int);
    validateType("d", d, ValidationType.Int);
    validateType("l", l, ValidationType.Int);

    this.x = x;
    this.y = y;
    this.z = z;
    this.t = t;
    this.w = w;
    this.h = h;
    this.d = d;
    this.l = l;
  }
}

/**
 * Search area for audio-based analysis.
 * @param {number} startTime Start time of the search area in seconds.
 * @param {number} endTime End time of the search area in seconds.
 * @param {number} minFreq Minimum frequency of search area in Hertz.
 * @param {number} maxFreq Maximum frequency of search area in Hertz.
 * @param {array[int]} channels Channels within search area.
 */
export class AudioSearchArea extends SearchArea {
  constructor(startTime, endTime, minFreq, maxFreq, channels) {
    super();

    // Input validation
    validateType("startTime", startTime, ValidationType.Number);
    validateType("endTime", endTime, ValidationType.Number);
    validateType("minFreq", minFreq, ValidationType.Number);
    validateType("maxFreq", maxFreq, ValidationType.Number);
    validateType("channels", channels, ValidationType.Array);
    channels.forEach((channel) => {
      validateType("channel", channel, ValidationType.Int);
    });

    this.startTime = startTime;
    this.endTime = endTime;
    this.minFreq = minFreq;
    this.maxFreq = maxFreq;
    this.channels = channels;
  }

  /**
   * Converts an object to an AudioSearchArea.
   * @param {Object} obj An object with the properties of the AudioSearchArea.
   * @returns {AudioSearchArea} The converted AudioSearchArea.
   * */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);
    return new AudioSearchArea(
      obj.startTime,
      obj.endTime,
      obj.minFreq,
      obj.maxFreq,
      obj.channels
    );
  }
}

//#endregion

/**
 * Instant Analysis Module Config.
 * Used to transmit the settings for applying a model to files within a project in the set structures.
 * @param {string} iamName Name of the instant analysis module (IAM) to be used.
 * @param {UUID} projectId Project Id of the project the IAM is applied to.
 * @param {Parameter[]} parameters Tool parameters of the IAM.
 * @param {UUID[]} fileIds File Ids of the files the IAM is applied to. Will be processed in the order of the array.
 * @param {int[]} structureIds Structure Ids of the structures the IAM is applied to. IAMs will be applied to structures in the order of the array.
 * @param {boolean} previewOnly If true, the IAM results will not be saved, but only a preview will be generated. Default: false.
 * @param {SearchArea} searchArea Search area of the IAM. If null, the whole file will be processed. Default: null
 */
export default class IAMConfig {
  constructor(
    iamName,
    projectId,
    parameters,
    fileIds,
    structureIds,
    previewOnly = false,
    searchArea = null
  ) {
    validateType("iamName", iamName, ValidationType.String);

    if (!isUuid(projectId)) {
      throw TypeError(
        `projectId must be of type UUID, received ${typeof projectId}: ${projectId}`
      );
    }

    validateType("parameters", parameters, ValidationType.Array);
    parameters.forEach((parameter) => {
      validateInstance("parameter", parameter, Parameter);
    });

    validateType("fileIds", fileIds, ValidationType.Array);
    fileIds.forEach((fileId) => {
      if (!isUuid(fileId)) {
        throw TypeError(
          `fileIds must be of type array of UUID, received ${typeof fileId}: ${fileId}`
        );
      }
    });

    validateType("structureIds", structureIds, ValidationType.Array);
    structureIds.forEach((structureId) => {
      if (!isInt(structureId) || structureId < 0) {
        throw TypeError(
          `structureIds must be of type array of positive integers, received ${typeof structureId}: ${structureId}`
        );
      }
    });

    validateType("previewOnly", previewOnly, ValidationType.Bool);
    if (searchArea !== null)
      validateInstance("searchArea", searchArea, SearchArea);

    this.iamName = iamName;
    this.projectId = projectId;
    this.parameters = parameters;
    this.fileIds = fileIds;
    this.structureIds = structureIds;
    this.previewOnly = previewOnly;
    this.searchArea = searchArea;
  }

  /**
   * Converts an object to an IAMConfig.
   * @param {Object} obj An object with the properties of the IAMConfig.
   * @returns {IAMConfig} The converted IAMConfig.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);
    return new IAMConfig(
      obj.iamName,
      obj.projectId,
      obj.parameters.map((param) => Parameter.fromObject(param)),
      obj.fileIds,
      obj.structureIds,
      obj.previewOnly,
      obj.searchArea
    );
  }
}
