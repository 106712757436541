// HSA imports
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  validateInstance,
  validateType,
  ValidationType,
} from "../utils/Utils";

// UIComponent sub-data class for Parameter
class UIComponent {
  /**
   * Create a new UIComponent of a InstantAnalysisModule Parameter.
   * @param {string} component The type of the UI component.
   * @param {string} label The label of the UI component.
   * @param {*} min Minimum value. For Sliders.
   * @param {*} max Maximum value. For Sliders.
   * @param {*} step Step size. For Sliders.
   * @param {*} default Initial value.
   * @param {*} value Optional. Current value. Default null.
   * @param {boolean | null} optional Optional. If ui should be inside an accordion. Default false.
   * @param {boolean} hidden Optional. If ui element should be hidden. Default false.
   * @param {array[int] | null} hiddenStructures Optional. Structure Ids where UI element should be hidden. -1 for all. Default [].
   * @param {array[object]} options Optional. Available options for e.g. select component. Default [].
   */
  constructor(
    component,
    label,
    min,
    max,
    step,
    defaultValue,
    value = null,
    optional = false,
    hidden = false,
    hiddenStructures = [],
    options = []
  ) {
    // Input validation
    validateType("component", component, ValidationType.String);
    validateType("label", label, ValidationType.String);
    validateType("optional", optional, ValidationType.Bool);
    validateType("hidden", hidden, ValidationType.Bool);
    validateType("hiddenStructures", hiddenStructures, ValidationType.Array);
    hiddenStructures.forEach((structure) => {
      validateType("hiddenStructures", structure, ValidationType.Int);
    });
    validateType("options", options, ValidationType.Array);

    this.component = component;
    this.label = label;
    this.min = min;
    this.max = max;
    this.step = step;
    this.default = defaultValue;
    this.value = value ?? null;
    this.optional = optional ?? false;
    this.hidden = hidden ?? false;
    this.hiddenStructures = hiddenStructures ?? [];
    this.options = options ?? [];
  }

  /**
   * Create a new UIComponent from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per UIComponent constructor.
   * @param {object} obj An object containing all necessary properties of a UIComponent.
   * @returns {UIComponent} A new UIComponent
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new UIComponent(
      obj.component,
      obj.label,
      obj.min,
      obj.max,
      obj.step,
      obj.default,
      obj.value,
      obj.optional,
      obj.hidden,
      obj.hiddenStructures ?? undefined,
      obj.options
    );
  }
}

// Parameter sub-data class for InstantAnalysisModule
export class Parameter {
  /**
   * Create a new Parameter of a InstantAnalysisModule.
   * @param {string} name The name of the parameter.
   * @param {string | null} description Optional. The description of the parameter. Defaults to "".
   * @param {string} type The type of the parameter.
   * @param {UIComponent} ui The UI component of the parameter.
   */
  constructor(name, description = "", type, ui) {
    validateType("name", name, ValidationType.String);
    validateType("description", description, ValidationType.String);
    validateType("type", type, ValidationType.String);
    validateInstance("ui", ui, UIComponent);

    this.name = name;
    this.description = description ?? "";
    this.type = type;
    this.ui = ui;
  }

  /**
   * Create a new Parameter from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per Parameter constructor.
   * @param {object} obj An object containing all necessary properties of a Parameter.
   * @returns {Parameter} A new Parameter
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new Parameter(
      obj.name,
      obj.description,
      obj.type,
      UIComponent.fromObject(obj.ui)
    );
  }
}

// InstantAnalysisModule data class
export default class InstantAnalysisModule {
  /**
   * Create a new InstantAnalysisModule.
   * @param {string} name The name of the module.
   * @param {string} tooltip The tooltip of the module.
   * @param {string} description Optional. The description of the module. Defaults to "".
   * @param {boolean} preview Optional. Whether the module is a preview module. Defaults to false.
   * @param {boolean} shared Optional. Whether the module is a shared module. Defaults to false.
   * @param {string} icon The icon of the module.
   * @param {Parameter[]} toolParams The tool parameters of the module.
   * @param {object} parameters Optional. Further parameters of the module. Defaults to {}.
   */
  constructor(
    name,
    tooltip,
    description = "",
    preview = false,
    shared = false,
    icon,
    toolParams,
    parameters = {}
  ) {
    // Input validation
    validateType("name", name, ValidationType.String);
    validateType("tooltip", tooltip, ValidationType.String);
    validateType("description", description, ValidationType.String);
    validateType("preview", preview, ValidationType.Bool);
    validateType("shared", shared, ValidationType.Bool);
    validateType("icon", icon, ValidationType.String);
    validateType("toolParams", toolParams, ValidationType.Array);
    toolParams.forEach((parameter) => {
      validateInstance("toolParams", parameter, Parameter);
    });
    validateType("parameters", parameters, ValidationType.Object);

    this.name = name;
    this.tooltip = tooltip;
    this.description = description ?? "";
    this.preview = preview ?? false;
    this.shared = shared ?? false;
    this.icon = icon;
    this.toolParams = toolParams;
    this.parameters = parameters ?? {};
  }

  /**
   * Create a new InstantAnalysisModule from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per InstantAnalysisModule contructor.
   * @param {object} obj An object containing all necessary properties of a InstantAnalysisModule.
   * @returns {InstantAnalysisModule} A new InstantAnalysisModule
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    validateType("obj.toolParams", obj.toolParams, ValidationType.Array);

    return new InstantAnalysisModule(
      obj.name,
      obj.tooltip,
      obj.description,
      obj.preview,
      obj.shared,
      obj.icon,
      obj.toolParams.map((p) => Parameter.fromObject(p)),
      obj.parameters
    );
  }
}
