import React, { Component } from "react";
import PropTypes from "prop-types";

import { RegionROI } from "../utils/ROI";

import { withPersistentStorage } from "./PersistentStorageContext";
import { withProject } from "./ProjectContext";

import { getParentIndexLayer } from "../utils/StructuresUtils";

const ProjectHistoryContext = React.createContext();

export const withProjectHistory = (Component) => {
  const WrappedComponent = (props) => (
    <ProjectHistoryContext.Consumer>
      {(context) => <Component {...props} projectHistory={context} />}
    </ProjectHistoryContext.Consumer>
  );

  WrappedComponent.displayName = `withProjectHistory(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// this is the main component, it has to be added at the root of the app.
// all components that use withProject(...) will have access to it via this.props.Project...
// There is not limit for steps, since it should also be used to save new Annotations to the project
class ProjectHistoryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canUndo: false,
      canRedo: false,
      sizeLimitReached: false,
    };
    this.socket = null;
    this.shownSaveHintNum = 0;
    window.projectHistory = this;
  }

  /**
   * Initializes the Project provider and passes a viewer component
   *
   * @param {object} viewer - Viewer Component
   */
  init = (viewer) => {
    this.viewer = viewer;
    this.collecting = false;
    this.collection = [];
    this.past = [];
    this.future = [];
  };

  send = (stepType, items) => {
    const toSend = {
      state: "anno",
      stepType,
    };
    if (stepType === "pastHistory") {
      toSend.items = items.map((item) => {
        return item.map((itm) => {
          return {
            add: itm.add,
            id: itm.id,
            roi: itm.roi.regions,
          };
        });
      });
    } else if (typeof items !== "undefined") {
      toSend.items = items.map((item) => {
        return {
          add: item.add,
          id: item.id,
          roi: item.roi.regions,
        };
      });
    }

    // this.socket.send(JSON.stringify(toSend));
  };

  receive = (input) => {
    const { structures } = this.viewer.props.projectContext;
    if (input.state === "init" && this.past) {
      this.send("pastHistory", this.past);
      return;
    }
    if (input.stepType === "pastHistory" && input.items) {
      for (const inputItems of input.items) {
        const items = inputItems.map((item) => {
          const roiStructure = structures.find((str) => str.id === item.id);
          return {
            add: item.add,
            id: item.id,
            roi: new RegionROI({
              regions: [item.roi],
              structureId: item.id,
              color: roiStructure.color,
              isSubtype: roiStructure.classificationSubtype,
              subtypeName: roiStructure.label,
            }),
          };
        });
        this.past.push(items);
        this.processItem(items, "redo");
      }
      this.future = [];
      this.updateState();
      return;
    }
    if (input.items) {
      const items = input.items.map((item) => {
        const roiStructure = structures.find((str) => str.id === item.id);
        return {
          add: item.add,
          id: item.id,
          roi: new RegionROI({
            regions: [item.roi],
            structureId: item.id,
            color: roiStructure.color,
            isSubtype: roiStructure.classificationSubtype,
            subtypeName: roiStructure.label,
          }),
        };
      });
      if (input.stepType === "redo") {
        this.past.push(items);
        this.future = [];
      } else if (input.stepType === "undo") {
        this.future.push(items);
      }
      this.updateState();
      this.processItem(items, input.stepType);
    } else {
      if (input.stepType === "undo") {
        this.undoItem();
      } else if (input.stepType === "redo") {
        this.redoItem();
      }
    }
  };

  add = (items) => {
    if (items.length > 0) {
      items = items.map((item) => {
        item.roi = item.roi.copy();
        item.id = item.roi.structureId;
        return item;
      });
      if (this.collecting) {
        this.collection.push(items);
      } else {
        this.send("redo", items);
        this.past.push(items);
        this.future = [];
      }
      this.updateState();
      if (Math.floor(this.past.length / 100) > this.shownSaveHintNum) {
        this.shownSaveHintNum++;
        window.showActionSnackbar(
          "Would you like to save your " + this.past.length + " changes?",
          this.props.projectContext.viewer.onSaveClick,
          "Save now",
          "Dismiss"
        );
      }
    }
  };

  //intern function, adds and removes polys for one item (history step)
  processItem = (items, stepType) => {
    if (items.length === 0) return;

    const { fileId, structures } = this.viewer.props.projectContext;
    let roiLayers = this.viewer.props.projectContext.roiLayers[fileId];

    for (let action of items) {
      let parentLayerIdx = -1;
      try {
        parentLayerIdx = getParentIndexLayer(
          structures.find((str) => str.id === action.id),
          structures
        );
      } catch (ex) {
        console.log(stepType, "error:", ex);
        window.showErrorSnackbar(
          "Something went wrong, " + stepType + " ignored!"
        );
        return;
      }
      const parentRoiLayer = roiLayers[parentLayerIdx];

      if (
        (stepType === "redo" && action.add) ||
        (stepType === "undo" && !action.add)
      ) {
        const overlapItems = parentRoiLayer.tree.search(action.roi.treeItem);
        let isSame = false;
        for (const treeItem of overlapItems) {
          if (treeItem.roi.sameBoundsWith(action.roi)) {
            isSame = true;
            break;
          }
        }
        if (!isSame) {
          parentRoiLayer.tree.insert(action.roi.treeItem);
        }
      } else {
        parentRoiLayer.tree.remove(action.roi.treeItem, (a, b) => {
          return a.roi.sameBoundsWith(b.roi);
        });
      }
      parentRoiLayer.layer.regionRois = parentRoiLayer.tree
        .all()
        .map((treeItem) => treeItem.roi);
    }
  };

  undoItem = () => {
    if (this.past.length > 0) {
      let item = this.past.pop();
      this.processItem(item, "undo");
      this.future.push(item);
      this.updateState();
      return true;
    }
    return false;
  };

  undo = () => {
    const actionDone = this.undoItem();
    if (actionDone) {
      this.send("undo");
    }
  };

  redoItem = () => {
    if (this.future.length > 0) {
      let item = this.future.pop();
      this.processItem(item, "redo");
      this.past.push(item);
      this.updateState();
      return true;
    }
    return false;
  };

  redo = () => {
    const actionDone = this.redoItem();
    if (actionDone) {
      this.send("redo");
    }
  };

  clear = () => {
    this.past = [];
    this.future = [];
    this.shownSaveHintNum = 0;
    this.updateState();
  };

  updateState = () => {
    this.setState({
      canUndo: this.past.length > 0,
      canRedo: this.future.length > 0,
    });
  };

  getHistoryLength = () => {
    return this.past.length;
  };

  mergePastItems(n) {
    if (n > 1) {
      let newItem = [];
      for (let i = 0; i < n; i++) {
        let item = this.past.pop();
        newItem.push(...item);
      }
      this.past.push(newItem);
      this.future = [];
    }
  }

  startCollection() {
    this.collecting = true;
  }

  addCollection() {
    this.collecting = false;
    let mergedItems = [];
    while (this.collection.length > 0) {
      const items = this.collection.pop();
      mergedItems.push(...items);
    }
    this.collection = [];
    this.add(mergedItems);
  }

  render() {
    return (
      <ProjectHistoryContext.Provider
        value={{
          init: this.init,
          collecting: this.collecting,
          collection: this.collection,
          startCollection: this.startCollection,
          addCollection: this.addCollection,
          add: this.add,
          undo: this.undo,
          redo: this.redo,
          clear: this.clear,
          getHistoryLength: this.getHistoryLength,
          mergePastItems: this.mergePastItems,
          ...this.state,
        }}
      >
        {this.props.children}
      </ProjectHistoryContext.Provider>
    );
  }
}

ProjectHistoryProvider.propTypes = {
  historyDepth: PropTypes.number.isRequired,
  projectContext: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default withPersistentStorage(withProject(ProjectHistoryProvider));
