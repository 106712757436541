// RandomColorGenerator.jsx
// This file contains functions to generate "random" colors from a predetermined list of colors.

// Copyright HS Analysis GmbH, 2022
// Author: Valentin Haas

import Structure from "../components/Structure";
import { ValidationType, validateType, validateInstance } from "./Utils";

/**
 * Predetermined colors to be used.
 */
export const presetColors = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#000000",
];

/**
 * Get a random color, the first 21 colors are predetermined.
 * @param {int} colorIdx Optional. Used when querying a color and wanting to use the predetermined colors. Defaults to -1.
 * @returns {string} A string representing a color #01234EF.
 */
export function getRandomColor(colorIdx = null) {
  if (colorIdx !== null) validateType("colorIdx", colorIdx, ValidationType.Int);
  if (colorIdx !== null && colorIdx < 0)
    throw RangeError(
      `colorIdx must be of type integer >= 0, received ${typeof colorIdx}: ${colorIdx}`
    );

  var letters = "0123456789ABCDEF";
  var color = "#";
  if (colorIdx >= 0 && colorIdx < presetColors.length) {
    color = presetColors[colorIdx];
    colorIdx++;
  } else {
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  }
  return color;
}

/**
 * Get a color currently not used in structures.
 * @param {array} structures Existing structures.
 * @param {bool} UNSAFE_checkStructureType Optional. Validate structure type. Defaults to true.
 * @returns {string} A string representing a color #01234EF.
 */
export function getUnusedColor(structures, UNSAFE_checkStructureType = true) {
  validateType("structures", structures, ValidationType.Array);
  if (UNSAFE_checkStructureType) {
    structures.forEach((s) => {
      validateInstance("structure", s, Structure);
    });
  }

  let counter = 0;
  let color = getRandomColor(counter);
  while (structures.some((s) => s.color === color) && counter < 50) {
    counter++;
    color = getRandomColor(counter);
  }
  return color;
}
