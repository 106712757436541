// ROI.jsx
// Copyright HS Analysis GmbH, 2019
// Author: Viktor Eberhardt, Sebastian Murgul

// External imports
import * as turf from "@turf/turf";

// HSA Imports
import { calcBoundingBox, simplifyRegions } from "../utils/PolygonUtil";
import { v4 as uuidv4 } from "uuid";
import { ImageRoi, ModificationStatus } from "../../common/components/RoiTypes";

// Base class of a roi
// Depreciated?
export class BaseROI {
  constructor() {
    this.strokeColor = "green";
    this.fillColor = null;
  }
}

export class CommentROI {
  constructor(regions, color, type, commentValue, computedValue) {
    this.inverted = false;
    this.fontScaleFactor = 1;
    this.regions = regions;
    this.color = color;
    this.type = type;
    this.commentValue = commentValue;
    this.computedValue = computedValue;
    this.updateBounds();
  }

  updateBounds() {
    this.bounds = calcBoundingBox([this.regions]);
    this.width = this.bounds.right - this.bounds.left;
    this.height = this.bounds.bottom - this.bounds.top;
  }

  containsPoint(p) {
    return !(
      p.x < this.bounds.left ||
      p.x > this.bounds.right ||
      p.y < this.bounds.top ||
      p.y > this.bounds.bottom
    );
  }
}

// polygon roi
export class RegionROI {
  isObject = false;
  constructor(inputObj) {
    //this.regions = bufferSimplifyRegions(this.regions); //simplify old regions with small gap
    this.uuid = inputObj.uuid ? inputObj.uuid : uuidv4();
    this.inverted = false;

    let obj = {};
    if (inputObj instanceof ImageRoi) {
      obj.regions = inputObj.coordinates;
      obj.ai = inputObj.aiAnnotated;
      obj.z = inputObj.z;
      obj.t = inputObj.t;
      obj.structureId = inputObj.structure;
      obj.modificationStatus = inputObj.modificationStatus;
    } else {
      obj = inputObj;
      obj.modificationStatus = ModificationStatus.Added;
    }
    this.setRegions(obj.regions ? obj.regions : obj);

    this.center = {
      x: this.bounds.left + (this.bounds.right - this.bounds.left) / 2,
      y: this.bounds.top + (this.bounds.bottom - this.bounds.top) / 2,
    };
    this.borderColor = "4px solid white";

    this.isSubtype = obj.subtype
      ? obj.subtype
      : obj.isSubtype
      ? obj.isSubtype
      : false;
    this.color = obj.color1 ? obj.color1 : obj.color ? obj.color : "#FFFFFF";
    this.subtypeName = obj.name
      ? obj.name
      : obj.subtypeName
      ? obj.subtypeName
      : "";
    this.aiAnnotated = obj.ai
      ? obj.ai
      : obj.aiAnnotated
      ? obj.aiAnnotated
      : false;
    this.isAnnotated = obj.annotated
      ? obj.annotated
      : obj.isAnnotated
      ? obj.isAnnotated
      : false;
    this.isLabeled = obj.labeled
      ? obj.labeled
      : obj.isLabeled
      ? obj.isLabeled
      : false;
    this.isSelObj = obj.isSel ? obj.isSel : obj.isSelObj ? obj.isSelObj : false;
    this.isSaved = obj.saved ? obj.saved : obj.isSaved ? obj.isSaved : false;
    this.z = obj.z ? obj.z : 0;
    this.fullyLoaded = obj.fullyLoaded ? obj.fullyLoaded : false;
    this.comment = obj.comment ? obj.comment : "";
    this.tileName = obj.tileName ? obj.tileName : "";
    this.structureId = obj.structureId ? obj.structureId : 0;
    this.selected = obj.selected ? obj.selected : false;
    this.selectedWithKey = obj.selectedWithKey ? obj.selectedWithKey : false;
    this.frequencyClass = obj.frequencyClass ? obj.frequencyClass : -1;
    this.isObject = obj.isObject ? obj.isObject : false;
    if (this.regions[0].length > 5) this.isObject = false; //can not be object

    this.isStartData = false;
    this.aiIdx = 0;
    this.galleryIndex = null;
    this.firstTimeGallery = true;

    this.treeItem = {
      minX: this.bounds.left,
      minY: this.bounds.top,
      maxX: this.bounds.right,
      maxY: this.bounds.bottom,
      roi: this,
    };
  }

  /**
   * Converts the current RegionROI format to the ImageRoi format.
   * @param {uuid} fileId Database file id.
   * @param {uuid} userId Database user id.
   * @returns {ImageRoi} An ImageRoi object.
   */
  convertToImageROI = (fileId, userId) => {
    return ImageRoi.fromObject({
      modificationStatus: 0,
      structure: this.structureId,
      z: this.z,
      t: 0,
      coordinates: this.regions,
      minX: Math.floor(this.bounds.left),
      minY: Math.floor(this.bounds.top),
      maxX: Math.ceil(this.bounds.right),
      maxY: Math.ceil(this.bounds.bottom),
      annotationType: 0,
      id: this.uuid,
      fileId: fileId,
      isAiAnnotated: this.aiAnnotated,
      user: userId,
    });
  };

  copy = () => {
    let roiCopy = new RegionROI(this);
    roiCopy.regions = JSON.parse(JSON.stringify(this.regions)); // [...this.regions];
    roiCopy.uuid = this.uuid;
    roiCopy.color = this.color;
    roiCopy.isSubtype = this.isSubtype;
    roiCopy.isAnnotated = this.isAnnotated;
    roiCopy.subtypeName = this.subtypeName;
    roiCopy.structureId = this.structureId;
    roiCopy.isLabeled = this.isLabeled;
    roiCopy.isSelObj = this.isSelObj;
    roiCopy.aiAnnotated = this.aiAnnotated;
    return roiCopy;
  };

  getBoundsColorId = () => {
    return (
      this.bounds.left +
      "_" +
      this.bounds.top +
      "_" +
      this.bounds.right +
      "_" +
      this.bounds.bottom +
      "_" +
      this.color
    );
  };

  getPointOnPoly() {
    let poly = turf.polygon(this.regions);
    var pointOnPolygon = turf.centerOfMass(poly);
    return {
      x: pointOnPolygon.geometry.coordinates[0],
      y: pointOnPolygon.geometry.coordinates[1],
    };
  }

  /** Create turf region for coordinate set.
   *
   * @param {Array} regions Regions to be edited. Must have format [[[x,y], ..., [x,y]]]
   */
  setRegions(regions) {
    try {
      // Allow redundant styles of nesting
      if (typeof regions[0][0] === "number") {
        regions = [regions];
      } else if (typeof regions[0][0][0] === "number") {
        // regions = regions; -> Do nothing
      } else {
        regions = regions[0];
      }

      // use less float digits
      for (let region of regions) {
        for (let point of region) {
          point[0] = +point[0].toFixed(2);
          point[1] = +point[1].toFixed(2);
        }
      }

      for (let region of regions) {
        if (
          region[0][0] !== region[region.length - 1][0] ||
          region[0][1] !== region[region.length - 1][1]
        ) {
          region.push(region[0]);
        }
      }
      this.bounds = calcBoundingBox(regions);
      try {
        let poly = turf.polygon(regions);
        let converted = turf.toWgs84(poly);
        this.area = turf.area(converted);
      } catch (e) {
        this.area = -1;
      }
      if (this.area < 0) {
        this.area =
          (this.bounds.right - this.bounds.left) *
          (this.bounds.bottom - this.bounds.top);
      }

      this.regions = regions; // simplifyRegions(regions, 0.01);
    } catch {
      console.log("Error, regions could not be set:", regions);
    }
  }

  removeDuplicates(inputRegions) {
    for (let regions of inputRegions) {
      let resultRegions = [];
      if (regions.length > 2) {
        regions.push(regions[0]);
        let resultRegions = [regions[0]];
        for (let i = 1; i < regions.length; i++) {
          let p1 = regions[i - 1];
          let p2 = regions[i];
          let isEqual = p1[0] === p2[0] && p1[1] === p2[1];
          if (!isEqual) {
            resultRegions.push(p2);
          }
        }
      }
      inputRegions = resultRegions;
    }
    return inputRegions;
  }

  getRectRegions() {
    return [
      [
        [this.bounds.left, this.bounds.top],
        [this.bounds.right, this.bounds.top],
        [this.bounds.right, this.bounds.bottom],
        [this.bounds.left, this.bounds.bottom],
        [this.bounds.left, this.bounds.top],
      ],
    ];
  }

  getSimplifiedRectRegions() {
    return [
      [
        [parseInt(this.bounds.left, 10), parseInt(this.bounds.top, 10)],
        [parseInt(this.bounds.right, 10), parseInt(this.bounds.top, 10)],
        [parseInt(this.bounds.right, 10), parseInt(this.bounds.bottom, 10)],
        [parseInt(this.bounds.left, 10), parseInt(this.bounds.bottom, 10)],
        [parseInt(this.bounds.left, 10), parseInt(this.bounds.top, 10)],
      ],
    ];
  }

  //return regions, so that rendering is still fast
  getDynamicRegions(comp, numObjects) {
    if (comp > 0.001 || numObjects < 100) {
      //if big, return original
      return this.regions;
    }

    if (comp < 0.00005) {
      //if very small return rectangles (bounding box)
      return this.getSimplifiedRectRegions();
    } else if (typeof this.intRegions === "undefined") {
      //else return rounded polygon
      this.intRegions = this.regions.map((regions) => {
        return regions.map((p) => [parseInt(p[0], 10), parseInt(p[1], 10)]);
      });
    }

    if (comp < 0.0005) {
      if (typeof this.simplifyedRegions === "undefined") {
        this.simplifyedRegions = simplifyRegions(this.intRegions, 1);
      }
      return this.simplifyedRegions;
    }
    return this.intRegions;
  }

  intersects(p1, p2) {
    return !(
      p2.x < this.bounds.left ||
      p1.x > this.bounds.right ||
      p2.y < this.bounds.top ||
      p1.y > this.bounds.bottom
    );
  }

  boundsAreaCompare(p1, p2) {
    let pArea = Math.abs(p2.x - p1.x) * Math.abs(p2.y - p1.y);
    let b = this.bounds;
    let area = Math.abs(b.right - b.left) * Math.abs(b.bottom - b.top);
    return area / pArea;
  }

  sameBoundsWith(roi) {
    return (
      this.bounds.left === roi.bounds.left &&
      this.bounds.right === roi.bounds.right &&
      this.bounds.top === roi.bounds.top &&
      this.bounds.bottom === roi.bounds.bottom
    );
  }

  /**
   * Converts the current ImageRoi format to the legacy RegionROI format.
   * @param {ImageRoi | Obj} inputObj An ImageRoi object or an object with the same properties as an ImageRoi.
   * @returns {RegionROI} A RegionROI object.
   */
  static legacyRegionRoifromDatabase(inputObj) {
    if (!(inputObj instanceof ImageRoi)) {
      inputObj = new ImageRoi.fromObject(inputObj);
    }
    const newRegionRoi = {};
    newRegionRoi.aiAnnotated = inputObj.isAiAnnotated;
    newRegionRoi.modificationStatus = inputObj.modificationStatus;
    newRegionRoi.regions = inputObj.coordinates;
    newRegionRoi.structureId = inputObj.structure;
    newRegionRoi.t = inputObj.t;
    newRegionRoi.uuid = inputObj.id;
    newRegionRoi.z = inputObj.z;
    newRegionRoi.isAnnotated = false;
    newRegionRoi.isLabeled = false;
    newRegionRoi.isSelObj = false;
    newRegionRoi.isSaved = false;
    newRegionRoi.fullyLoaded = false;
    newRegionRoi.comment = "";
    newRegionRoi.tileName = "";
    newRegionRoi.selected = false;
    newRegionRoi.selectedWithKey = false;
    newRegionRoi.frequencyClass = -1;
    newRegionRoi.isObject = false;

    return new RegionROI(newRegionRoi);
  }
}

// simple recatangle roi
// Depreciated?
export class RectROI extends BaseROI {
  constructor(ctx, x, y, w, h, drawingMode = false) {
    super();
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.ctx = ctx;
    if (drawingMode) {
      this.resizePoint = 3;
    }
  }

  summary() {
    return (
      "Rect (" +
      Math.round(this.x) +
      "," +
      Math.round(this.y) +
      "," +
      Math.round(this.w) +
      "," +
      Math.round(this.h) +
      ")"
    );
  }

  handleMouseDown(p1) {
    let cornerRadius = 5 / this.ctx.getTransform().a;
    if (
      p1.x >= this.x - cornerRadius &&
      p1.x <= this.x + cornerRadius &&
      p1.y >= this.y - cornerRadius &&
      p1.y <= this.y + cornerRadius
    ) {
      this.resizePoint = 0;
      return 2;
    } else if (
      p1.x >= this.x + this.w - cornerRadius &&
      p1.x <= this.x + this.w + cornerRadius &&
      p1.y >= this.y - cornerRadius &&
      p1.y <= this.y + cornerRadius
    ) {
      this.resizePoint = 1;
      return 2;
    } else if (
      p1.x >= this.x - cornerRadius &&
      p1.x <= this.x + cornerRadius &&
      p1.y >= this.y + this.h - cornerRadius &&
      p1.y <= this.y + this.h + cornerRadius
    ) {
      this.resizePoint = 2;
      return 2;
    } else if (
      p1.x >= this.x + this.w - cornerRadius &&
      p1.x <= this.x + this.w + cornerRadius &&
      p1.y >= this.y + this.h - cornerRadius &&
      p1.y <= this.y + this.h + cornerRadius
    ) {
      this.resizePoint = 3;
      return 2;
    } else if (
      p1.x >= this.x &&
      p1.x <= this.x + this.w &&
      p1.y >= this.y &&
      p1.y <= this.y + this.h
    ) {
      this.dragStart = p1;
      return 1;
    }
  }

  handleMouseMove(p1) {
    let cornerRadius = 5 / this.ctx.getTransform().a;
    if (
      p1.x >= this.x - cornerRadius &&
      p1.x <= this.x + cornerRadius &&
      p1.y >= this.y - cornerRadius &&
      p1.y <= this.y + cornerRadius
    ) {
      this.ctx.canvas.style.cursor = "se-resize";
      this.hovered = true;
    } else if (
      p1.x >= this.x + this.w - cornerRadius &&
      p1.x <= this.x + this.w + cornerRadius &&
      p1.y >= this.y - cornerRadius &&
      p1.y <= this.y + cornerRadius
    ) {
      this.ctx.canvas.style.cursor = "ne-resize";
      this.hovered = true;
    } else if (
      p1.x >= this.x - cornerRadius &&
      p1.x <= this.x + cornerRadius &&
      p1.y >= this.y + this.h - cornerRadius &&
      p1.y <= this.y + this.h + cornerRadius
    ) {
      this.ctx.canvas.style.cursor = "ne-resize";
      this.hovered = true;
    } else if (
      p1.x >= this.x + this.w - cornerRadius &&
      p1.x <= this.x + this.w + cornerRadius &&
      p1.y >= this.y + this.h - cornerRadius &&
      p1.y <= this.y + this.h + cornerRadius
    ) {
      this.ctx.canvas.style.cursor = "se-resize";
      this.hovered = true;
    } else if (
      p1.x >= this.x &&
      p1.x <= this.x + this.w &&
      p1.y >= this.y &&
      p1.y <= this.y + this.h
    ) {
      if (this.ctx.canvas.style.cursor === "default") {
        this.ctx.canvas.style.cursor = "pointer";
      }
      this.hovered = true;
    } else {
      this.hovered = false;
    }
  }

  handleMouseUp() {
    // heal rois (fix negative width and height)
    if (this.w < 0) {
      this.x += this.w;
      this.w = -this.w;
    }
    if (this.h < 0) {
      this.y += this.h;
      this.h = -this.h;
    }
  }

  drag(p1, vw, vh) {
    // apply relative movement
    this.x += p1.x - this.dragStart.x;
    this.y += p1.y - this.dragStart.y;

    // check if out of image bounds
    this.x = Math.max(Math.min(vw - this.w, this.x), 0);
    this.y = Math.max(Math.min(vh - this.h, this.y), 0);

    // save cursor position
    this.dragStart = p1;
  }

  resize(p1, vw, vh) {
    // check if out of image bounds
    p1.x = Math.max(Math.min(vw, p1.x), 0);
    p1.y = Math.max(Math.min(vh, p1.y), 0);

    if (this.resizePoint === 0) {
      this.w -= p1.x - this.x;
      this.x = p1.x;
      this.h -= p1.y - this.y;
      this.y = p1.y;

      if ((this.w > 0 && this.h > 0) || (this.w < 0 && this.h < 0)) {
        this.ctx.canvas.style.cursor = "se-resize";
      } else {
        this.ctx.canvas.style.cursor = "ne-resize";
      }
    } else if (this.resizePoint === 1) {
      this.w += p1.x - this.w - this.x;
      this.h -= p1.y - this.y;
      this.y = p1.y;

      if ((this.w > 0 && this.h > 0) || (this.w < 0 && this.h < 0)) {
        this.ctx.canvas.style.cursor = "ne-resize";
      } else {
        this.ctx.canvas.style.cursor = "se-resize";
      }
    } else if (this.resizePoint === 2) {
      this.w -= p1.x - this.x;
      this.x = p1.x;
      this.h += p1.y - this.h - this.y;

      if ((this.w > 0 && this.h > 0) || (this.w < 0 && this.h < 0)) {
        this.ctx.canvas.style.cursor = "ne-resize";
      } else {
        this.ctx.canvas.style.cursor = "se-resize";
      }
    } else if (this.resizePoint === 3) {
      this.w += p1.x - this.w - this.x;
      this.h += p1.y - this.h - this.y;

      if ((this.w > 0 && this.h > 0) || (this.w < 0 && this.h < 0)) {
        this.ctx.canvas.style.cursor = "se-resize";
      } else {
        this.ctx.canvas.style.cursor = "ne-resize";
      }
    }
  }

  draw() {
    this.ctx.strokeStyle = this.strokeColor;

    if (this.hovered) this.ctx.strokeStyle = "yellow";

    // fixed line width
    this.ctx.lineWidth = 2 / this.ctx.getTransform().a;

    // custom drawing
    this.ctx.beginPath();
    this.ctx.rect(this.x, this.y, this.w, this.h);
    this.ctx.stroke();
    this.ctx.closePath();

    if (this.hovered) {
      this.ctx.lineWidth = 1 / this.ctx.getTransform().a;
      let cornerDiameter = 10 / this.ctx.getTransform().a;
      let cornerRadius = 5 / this.ctx.getTransform().a;

      this.ctx.fillStyle = "white";
      this.ctx.strokeStyle = "black";

      // draw corner haptic
      this.ctx.beginPath();
      this.ctx.rect(
        this.x - cornerRadius,
        this.y - cornerRadius,
        cornerDiameter,
        cornerDiameter
      );
      this.ctx.rect(
        this.x + this.w - cornerRadius,
        this.y - cornerRadius,
        cornerDiameter,
        cornerDiameter
      );
      this.ctx.rect(
        this.x - cornerRadius,
        this.y + this.h - cornerRadius,
        cornerDiameter,
        cornerDiameter
      );
      this.ctx.rect(
        this.x + this.w - cornerRadius,
        this.y + this.h - cornerRadius,
        cornerDiameter,
        cornerDiameter
      );
      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }
}

function getFirstValidatedIndexValue(item, index) {
  if (item && item[index] && item[index].length > 0) {
    return item[index][0];
  } else {
    return item;
  }
}

function getOnlyValidatedSimpleIndexValue(item, index) {
  if (item && item[index] && item[index].length > 0) {
    return item[index];
  } else {
    return null;
  }
}

export function createRoisFromAnno(anno, structureId = null) {
  if (structureId == null && typeof anno.geoJSON.structureId === "undefined") {
    console.error(
      "StructureId is not defined in annotation and not passed as parameter",
      { structureId, anno }
    );
  }
  return anno.geoJSON.coordinates
    .filter((c) => c.length > 0)
    .map((c, index) => {
      const r = new RegionROI({
        uuid: getOnlyValidatedSimpleIndexValue(anno.geoJSON.uuid, index),
        regions: c,
        color1: getFirstValidatedIndexValue(anno.geoJSON.color1, index),
        subtype: getFirstValidatedIndexValue(anno.geoJSON.subtype, index),
        name: getFirstValidatedIndexValue(anno.geoJSON.name, index),
        ai: getFirstValidatedIndexValue(anno.geoJSON.aiAnnotated, index),
        annotated: getFirstValidatedIndexValue(anno.geoJSON.isAnnotated, index),
        labeled: getFirstValidatedIndexValue(anno.geoJSON.isLabeled, index),
        isSel: getFirstValidatedIndexValue(anno.geoJSON.isSelObj, index),
        saved: getFirstValidatedIndexValue(anno.geoJSON.isSaved, index),
        z: getFirstValidatedIndexValue(anno.geoJSON.z, index),
        comment: getFirstValidatedIndexValue(anno.geoJSON.comment, index),
        tileName: getFirstValidatedIndexValue(anno.geoJSON.tileName, index),
        structureId:
          structureId ??
          getFirstValidatedIndexValue(anno.geoJSON.structureId, index),
        isObject: getFirstValidatedIndexValue(anno.geoJSON.isObject, index),
        frequencyCl: getFirstValidatedIndexValue(anno.geoJSON.frequencyClass),
      });
      return r;
    });
}
