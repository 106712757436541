// authenication.service.js
// This is a service that is used to handle the login and logout of a user. It uses the fetch API to make requests to the server. It also uses the BehaviorSubject from the rxjs library to store the current user in the local storage and keep the user logged in between page refreshes.

// Copyright HS Analysis GmbH, 2019
// Author: Viktor Eberhardt

// External imports
import { BehaviorSubject } from "rxjs";

// HSA imports
import { handleResponse } from "../utils";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  isLoggedIn,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`/api/user/authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}

/**
 * Checks if the user is logged in by sending a request to the server to verify the token.
 * @returns {boolean} True if the user is logged in, false otherwise.
 */
async function isLoggedIn() {
  if (!currentUserSubject?.value?.token) {
    return false;
  }

  const canAuthenicate = await fetch(`/api/about/is_alive_auth`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${currentUserSubject.value.token}`,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return Promise.resolve(true);
        } else {
          localStorage.removeItem("currentUser");
          currentUserSubject.next(null);
          return Promise.resolve(false);
        }
      },
      () => {
        localStorage.removeItem("currentUser");
        currentUserSubject.next(null);
        return Promise.resolve(false);
      }
    )
    .catch(() => {
      localStorage.removeItem("currentUser");
      currentUserSubject.next(null);
      return Promise.resolve(false);
    });

  return canAuthenicate;
}

/**
 * Logs the user out and removes the user from local storage.
 */
async function logout() {
  const loggedIn = await isLoggedIn();
  if (!loggedIn) return;

  fetch(`/api/user/logout`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUserSubject.value.token}`,
    },
  }).then(
    (response) => {
      if (response.ok) {
        // Remove user from local storage to log user out.
        localStorage.removeItem("currentUser");
        currentUserSubject.next(null);
        window.location.reload();
        Promise.resolve();
      } else {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          const error = (data && data.message) || response.statusText;
          console.error("Error logging out: " + error);
          return Promise.reject(error);
        });
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
