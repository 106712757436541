// StepFileSelection.jsx
// File selection dialog for creating a new project.

// Copyright HS Analysis GmbH, 2022
// Authors: Viktor Eberhardt, Valentin Haas, Jakob Burghardt

// Framework imports
import React, { Component } from "react";
import PropTypes from "prop-types";

// External packages
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";

// Icon imports
import AddToPhotos from "@mui/icons-material/AddToPhotos";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import CheckBox from "@mui/icons-material/CheckBox";
import Folder from "@mui/icons-material/Folder";
import Image from "@mui/icons-material/Image";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";

// HSA KIT Components
import Backend from "../../../common/utils/Backend";
import EmptySlideCreation from "./EmptySlideCreation";
import FileFormatUtils from "../../../common/utils/FileFormatUtils";

const styles = () => ({
  root: {},
  freeHistoParameters: {},
  applyButton: {
    width: "100%",
  },
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    height: 562,
  },
  fileList: {
    border: "2px solid rgb(218, 218, 218)",
    height: "100%",
    overflowY: "auto",
    minHeight: "calc(100% - 35px)",
    maxHeight: "500px",
  },
  selButton: {
    color: "black",
  },
  item: {
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#0000001a",
    },
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
  selItem: {
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#0000001a",
    },
    padding: "8px",
    border: `2px solid #0673C1`,
  },
});

// The openslide reader cannot work with non-ascii symbols
const non_utf8_capable_filetypes = ["mrxs", "svs", "ndpi", "svslide"];

class StepFileSelection extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      dirfiles: [],
      dirFilesCase: [],
      currentPath: "",
      lastFileName: "",
      filterText: "",
      sortMenuOpen: false,
      sortBy: "name",
      sortReverse: true,
      showFolders: true,
      caseItems: [],
      selCase: null,
      caseList: null,
    };

    this.updateFolderList("");
  }

  componentDidMount() {
    this._isMounted = true;
    Backend.listCases().then((data) => {
      if (!("cases" in data)) return;
      let items = [];
      data.cases.forEach((c) => {
        items.push({ id: c.data.Id, name: c.data.case_acc });
      });
      this.setMountedState({ caseItems: items, caseList: data });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  getLastDir = (folderPath) => {
    let pathEnding = "-1";
    let lastSeperator = folderPath.lastIndexOf("/");
    if (lastSeperator < 0) {
      lastSeperator = folderPath.lastIndexOf(String.fromCharCode(92));
    }
    if (lastSeperator > 0) {
      pathEnding = folderPath.substr(lastSeperator + 1, folderPath.length);
    }
    return pathEnding;
  };

  onChangeFilter(e) {
    let filterText = e.target.value;
    let { currentPath } = this.state;
    this.setMountedState({ filterText });
    this.updateFolderList(currentPath, filterText);
  }

  getFilesFromPath = async (currentPath, filterText, addAll = false) => {
    let supportedFileFormats = [];

    switch (this.props.formData.projectType) {
      case "ProteomeAnalysis":
        supportedFileFormats = FileFormatUtils.supportedFormats.text;
        break;

      case "ESREvaluation":
      case "ESRTraining":
        supportedFileFormats = FileFormatUtils.supportedFormats.ESR;
        break;

      case "AudioAnnotator":
        supportedFileFormats = FileFormatUtils.supportedFormats.Audio;
        break;

      default:
        supportedFileFormats = FileFormatUtils.supportedFormats.Viewer;
    }
    Object.freeze(supportedFileFormats);

    let foldersToExclude = [];

    switch (this.props.formData.projectType) {
      case "HistoClassification":
      case "HistoPointCounting":
        foldersToExclude = ["DAT", "SCS", "ANS"];
        break;
      // you can add more cases here in the future
      default:
        break;
    }

    // load list of available files
    let directoryEntries = await Backend.walkDir(currentPath, true);
    directoryEntries = FileFormatUtils.filterViewerEntities(directoryEntries);

    let filteredDirFiles = [];
    let subfolderFiles = []; // define 'subfolderFiles' here

    for (const entry of directoryEntries) {
      let shouldBeAdded = false;

      if (entry.type === "directory") {
        const lastDir = this.getLastDir(entry.path);
        if (!foldersToExclude.includes(lastDir)) {
          if (addAll) {
            const subfiles = await this.getFilesFromPath(
              entry.path,
              filterText,
              addAll
            );
            if (subfiles === false) return false;
            this.addFileCount += subfiles.length;
            if (this.addFileCount > 500 && !this.addManyFilesConfirmed) {
              // Wrap your function call in a Promise
              try {
                let confirm = await new Promise((resolve, reject) => {
                  window.openResponseDialog(
                    "More than 500 entries found. Continue parsing?",
                    (response) => {
                      if (response) {
                        this.addManyFilesConfirmed = true;
                        resolve(true); // Resolve the Promise positively
                      } else {
                        reject(false); // Reject the Promise in case of negative response
                      }
                    }
                  );
                });

                // If user replies yes, the promise gets resolved and code execution will continue.
                if (!confirm) {
                  return false;
                }
              } catch (error) {
                // If user replies no, the promise gets rejected and control comes here.
                return false;
              }
            }

            // Now it is safe to use 'subfolderFiles'
            subfolderFiles = [...subfolderFiles, ...subfiles];
            shouldBeAdded = false;
          } else {
            shouldBeAdded = true;
          }
        }
      } else if (entry.type === "file") {
        const pathParts = entry.path.split(".");
        const extension = pathParts[pathParts.length - 1].toLowerCase();
        shouldBeAdded = true;
        if (!supportedFileFormats.includes(extension)) shouldBeAdded = false;
        if (pathParts[pathParts.length - 2] === "empty_file")
          shouldBeAdded = false;
      } else {
        shouldBeAdded = true;
      }

      if (filterText !== "" && shouldBeAdded) {
        const path = entry.path.split("/")[entry.path.split("/").length - 1];
        shouldBeAdded = path.toLowerCase().includes(filterText.toLowerCase());
      }

      if (shouldBeAdded) {
        filteredDirFiles.push(entry);
      }
    }

    let sortedDirFiles =
      FileFormatUtils.sortEntitiesByTypeAndName(filteredDirFiles);

    // If you want to combine 'sortedDirFiles' and 'subfolderFiles', do it here. Otherwise, return them separately
    return [...sortedDirFiles, ...subfolderFiles];
  };

  updateFolderList = (currentPath, filterText = "") => {
    this.getFilesFromPath(currentPath, filterText).then((dirfiles) => {
      // write received info into state
      // console.log([dirfiles[0]]);
      this.setMountedState({
        currentPath: currentPath,
        dirfiles: dirfiles,
      });
    });
  };

  addFile = (event, fileName) => {
    // Ensure that only ascii-conform paths are used.
    if (
      non_utf8_capable_filetypes.includes(
        fileName.split(".").pop().toLowerCase()
      )
    ) {
      let hasMoreThanAscii = [...fileName].some(
        (char) => char.charCodeAt(0) > 127
      );
      if (hasMoreThanAscii) {
        window.showErrorSnackbar(
          "File type does not support characters such as 'ä', 'ö', 'ü', or 'ß' in path."
        );
        window.openWarningDialog(
          "The file " +
            fileName +
            " or its path contain invalid characters such as 'ä', 'ö', 'ü', or 'ß'. \
            Please rename the file or its folder."
        );
        return;
      }
    }

    let { files } = this.props.formData;
    let { lastFileName, dirfiles } = this.state;
    let dirFilePaths = dirfiles.map((file) => file.path);
    if (event.shiftKey && lastFileName !== "") {
      let lastFileIdx = dirFilePaths.indexOf(lastFileName);
      if (lastFileIdx >= 0) {
        let fileIdx = dirFilePaths.indexOf(fileName);
        let firstIdx = Math.min(lastFileIdx, fileIdx);
        let lastIdx = Math.max(lastFileIdx, fileIdx);
        files = files.filter((file) => !dirFilePaths.includes(file));
        for (let i = firstIdx; i <= lastIdx; i++) {
          let fileNameToAdd = dirFilePaths[i];
          files.push(fileNameToAdd);
        }
        this.props.onChangeFiles(files.sort());
      }
    } else {
      if (files.includes(fileName)) {
        files = files.filter((c) => c !== fileName);
      } else {
        files.push(fileName);
      }
      this.setMountedState({ lastFileName: fileName });
      this.props.onChangeFiles(files.sort());
    }
  };

  toggleAllFiles = (currentPath) => {
    let { dirfiles } = this.state;
    let { files } = this.props.formData;
    if (dirfiles.filter((file) => files.includes(file.path)).length > 0) {
      this.removeFolder(currentPath);
    } else {
      this.addFolder(currentPath);
    }
  };

  addFolder = (folderPath, clickEvent = null) => {
    this.addFileCount = 0;
    this.addManyFilesConfirmed = false;
    this.getFilesFromPath(folderPath, "", true).then((dirfiles) => {
      if (dirfiles === false) return false;
      let filePaths = dirfiles.map((file) => file.path);
      let { files } = this.props.formData;
      let filteredFiles = filePaths.filter(
        (file) => !files.some((x) => file === x)
      );
      let filesToAdd = files.concat(filteredFiles);
      if (filesToAdd.length > 500) {
        window.openResponseDialog(
          "Add " + filesToAdd.length + " files?",
          (response) => {
            if (response) {
              this.props.onChangeFiles(filesToAdd);
            }
          }
        );
      } else {
        this.props.onChangeFiles(filesToAdd);
      }
    });
    if (clickEvent) clickEvent.stopPropagation();
  };

  removeFolder = () => {
    let { files } = this.props.formData;
    let dirFilePaths = this.state.dirfiles.map((file) => file.path);
    let resultFiles = files.filter((file) => dirFilePaths.indexOf(file) < 0);
    if (files.length !== resultFiles.length) {
      this.props.onChangeFiles(resultFiles.sort());
    }
  };

  isDisabled = (relativePath) => {
    let { project } = this.props;
    if (project && project.imageFiles) {
      const projectFiles = [
        ...new Set(project.imageFiles.map((file) => file.relativePath)),
      ];
      if (projectFiles.includes(relativePath)) return true;
    }
    return false;
  };

  toggleSortMenu = () => {
    this.setMountedState({ sortMenuOpen: !this.state.sortMenuOpen });
  };

  setSortBy = (value) => {
    const { sortBy, sortReverse, dirfiles } = this.state;

    let updatedSortReverse = false;
    if (value === sortBy) {
      updatedSortReverse = !sortReverse;
    } else {
      updatedSortReverse = sortReverse;
    }

    let sortedDirFiles = FileFormatUtils.sortEntitiesByTypeAndName(
      dirfiles,
      value,
      updatedSortReverse
    );

    this.setMountedState({
      dirfiles: sortedDirFiles,
      sortMenuOpen: false,
      sortBy: value,
      sortReverse: updatedSortReverse,
    });
  };

  switchFolderWithCases = () => {
    this.setMountedState({ showFolders: !this.state.showFolders });
  };

  handleItemClick = (caseId) => {
    if (this.state.selCase === caseId) {
      this.setMountedState({ selCase: null });
      let { files } = this.props.formData;
      files = [];
      this.props.onChangeFiles(files.sort());
      this.props.onChangeCaseId(null);
      return;
    }
    this.setMountedState({ selCase: caseId });
    this.props.onChangeCaseId(caseId);

    let pathArray = this.getFilePathsFromCaseList(caseId);

    let { files } = this.props.formData;
    files = [];
    this.props.onChangeFiles(files.sort());

    let caseFiles = [];

    for (let path of pathArray) {
      Backend.walkCaseFiles(path, true, (e) => {
        caseFiles.push(e);
        this.addCaseFile(e);
        this.setMountedState({ dirFilesCase: caseFiles });
      });
    }
  };

  getFilePathsFromCaseList = (caseId) => {
    let cases = this.state.caseList.cases;
    let foundCase = cases.find((obj) => obj.data.Id === caseId);
    if (foundCase === undefined) return [];
    return foundCase.filePaths;
  };

  addCaseFile = (file) => {
    let fileName = file.path;
    if (
      non_utf8_capable_filetypes.includes(
        fileName.split(".").pop().toLowerCase()
      )
    ) {
      let hasMoreThanAscii = [...fileName].some(
        (char) => char.charCodeAt(0) > 127
      );
      if (hasMoreThanAscii) {
        window.showErrorSnackbar(
          "File type does not support characters such as 'ä', 'ö', 'ü', or 'ß' in path."
        );
        window.openWarningDialog(
          "The file " +
            fileName +
            " or its path contain invalid characters such as 'ä', 'ö', 'ü', or 'ß'. \
            Please rename the file or its folder."
        );
        return;
      }
    }
    let { files } = this.props.formData;
    files.push(fileName);
    this.props.onChangeFiles(files.sort());
  };

  /**
   * Sets an image to the default error image when there is an error.
   * @param {field} e The field event
   */
  image_error = (e) => {
    e.target.src = "/img/image_error.svg";
  };

  render() {
    const { classes } = this.props;
    const { files } = this.props.formData;
    const {
      dirfiles,
      currentPath,
      filterText,
      sortMenuOpen,
      sortBy,
      sortReverse,
      showFolders,
    } = this.state;

    return (
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          style={{
            display: "grid",
            gridTemplateRows: "1fr auto",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Grid container style={{ overflow: "hidden" }}>
            <Grid item xs={6} style={{ height: "100%" }}>
              <div style={{ position: "relative" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="50px"
                >
                  <Box fontSize="16px">
                    All Files (
                    {dirfiles.filter((e) => e.type === "file").length}):
                  </Box>
                  <div>
                    <Button
                      fontSize="16px"
                      onClick={() => {
                        this.switchFolderWithCases();
                      }}
                      className={classes.selButton}
                      style={{
                        border: showFolders
                          ? "1px solid #0673C1"
                          : "1px solid black",
                        marginRight: "10px",
                        background: showFolders ? "#0673c114" : "none",
                      }}
                    >
                      Files
                    </Button>
                    <Button
                      fontSize="16px"
                      onClick={() => {
                        this.switchFolderWithCases();
                      }}
                      className={classes.selButton}
                      style={{
                        border: !showFolders
                          ? "1px solid #0673C1"
                          : "1px solid black",
                        background: !showFolders ? "#0673c114" : "none",
                      }}
                    >
                      Cases
                    </Button>
                  </div>
                  <Box>
                    <Tooltip disableInteractive title="Sort By">
                      <IconButton
                        ref={(node) => {
                          this.anchorEl = node;
                        }}
                        aria-controls="sort-menu"
                        aria-haspopup="true"
                        onClick={() => this.toggleSortMenu()}
                        size="large"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="sort-menu"
                      keepMounted
                      anchorEl={this.anchorEl}
                      open={sortMenuOpen}
                      onClose={() => this.toggleSortMenu()}
                    >
                      <MenuItem onClick={() => this.setSortBy("name")}>
                        Sort by name{" "}
                        {sortBy === "name" && (
                          <span>
                            {sortReverse ? <ArrowUpward /> : <ArrowDownward />}
                          </span>
                        )}
                      </MenuItem>
                      <MenuItem onClick={() => this.setSortBy("date")}>
                        Sort by creation date{" "}
                        {sortBy === "date" && (
                          <span>
                            {sortReverse ? <ArrowUpward /> : <ArrowDownward />}
                          </span>
                        )}
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </div>

              <div style={{ position: "absolute", top: 5, right: 24 }}>
                <SearchIcon
                  style={{
                    display: "inline-block",
                    fill: "lightgray",
                    marginTop: 20,
                  }}
                />
                <TextField
                  autoFocus
                  id="SearchFolderAndFiles"
                  variant="standard"
                  fullWidth
                  style={{
                    width: "200px",
                    marginLeft: 6,
                    marginTop: 0,
                    display: "inline-block",
                  }}
                  key={"filterText"}
                  margin="normal"
                  label={
                    "Filter " +
                    (this.state.showFolders ? "Folders/Files" : "Cases")
                  }
                  value={filterText}
                  onChange={(e) => this.onChangeFilter(e)}
                />
              </div>

              <List dense={true} className={classes.fileList}>
                {currentPath && showFolders && (
                  <ListItem>
                    <ListItemIcon
                      style={{ marginLeft: "-8px", marginRight: "-10px" }}
                    >
                      <Checkbox
                        disabled={this.state.selCase !== null}
                        name="CheckboxToggleAllFiles"
                        checked={
                          dirfiles.filter((file) => files.includes(file.path))
                            .length > 0
                        }
                        onChange={() => this.toggleAllFiles(currentPath)}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Tooltip
                        disableInteractive
                        title={`Up to "${
                          currentPath.substring(
                            0,
                            currentPath.lastIndexOf("/")
                          ) || "root"
                        }"`}
                      >
                        <IconButton
                          onClick={() => {
                            this.updateFolderList(
                              currentPath.substring(
                                0,
                                currentPath.lastIndexOf("/")
                              )
                            );
                          }}
                          size="large"
                        >
                          <ArrowUpward />
                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={currentPath} />
                  </ListItem>
                )}
                {showFolders &&
                  dirfiles.map((e, id) =>
                    e.type === "file" ? (
                      <ListItemButton
                        key={id}
                        disabled={
                          this.isDisabled(e.path) || this.state.selCase !== null
                        }
                        onClick={(clickEvent) =>
                          this.addFile(clickEvent, e.path)
                        }
                      >
                        <ListItemIcon>
                          <Tooltip
                            disableInteractive
                            title={
                              <div
                                style={{ display: "inline-block" }}
                                key={e.path}
                              >
                                <div style={{ display: "inline-block" }}>
                                  {
                                    <img
                                      style={{
                                        display: "block",
                                        background: "black",
                                        margin: "5px",
                                        objectFit: "contain",
                                      }}
                                      width="200"
                                      height="200"
                                      src={Backend.imageThumbnail(
                                        encodeURIComponent(e.path)
                                      )}
                                      onError={this.image_error}
                                      alt="Preview Image"
                                    />
                                  }
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {e.path}
                                  </div>
                                </div>
                              </div>
                            }
                          >
                            {files.includes(e.path) ? (
                              <CheckBox
                                disabled={this.state.selCase !== null}
                                style={{ color: "#0078D7" }}
                              />
                            ) : (
                              <Image />
                            )}
                          </Tooltip>
                        </ListItemIcon>
                        <Tooltip
                          disableInteractive
                          title={
                            <div>
                              <div
                                style={{ width: 80, display: "inline-block" }}
                              >
                                File Size:{" "}
                              </div>
                              {e.fileSize}
                              <br />
                              <div
                                style={{ width: 80, display: "inline-block" }}
                              >
                                Creation Date:{" "}
                              </div>
                              {e.creationTime}
                            </div>
                          }
                        >
                          <ListItemText
                            primary={e.path.replace(/^.*[\\/]/, "")}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              color: e.loaded ? "#08c21d" : "#000000",
                            }}
                          />
                        </Tooltip>
                      </ListItemButton>
                    ) : (
                      <ListItemButton
                        key={id}
                        onClick={() => this.updateFolderList(e.path)}
                      >
                        <ListItemIcon style={{ color: "#FFE896" }}>
                          <Folder />
                        </ListItemIcon>
                        <ListItemText
                          primary={e.path.replace(/^.*[\\/]/, "")}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip disableInteractive title="Add folder">
                            <span>
                              <IconButton
                                disabled={this.state.selCase !== null}
                                onClick={() => this.addFolder(e.path)}
                                size="large"
                              >
                                <AddToPhotos />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    )
                  )}
                {!showFolders &&
                  this.state.caseItems &&
                  this.state.caseItems
                    .filter((caseItem) =>
                      caseItem.name
                        .toLowerCase()
                        .includes(filterText.toLowerCase())
                    )
                    .map((item) => (
                      <li
                        key={item.id}
                        onClick={() => this.handleItemClick(item.id)}
                        className={
                          this.state.selCase === item.id
                            ? classes.selItem
                            : classes.item
                        }
                      >
                        {item.name}
                      </li>
                    ))}
              </List>
            </Grid>
            <Grid item xs={6} style={{ height: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="50px"
              >
                {this.props.selectionTarget} Files ({files.length}):
              </Box>
              <List dense={true} className={classes.fileList}>
                {files.map((e, i) => (
                  <Tooltip disableInteractive key={i} title={e}>
                    <span>
                      <ListItemButton
                        disabled={
                          this.isDisabled(e) || this.state.selCase !== null
                        }
                        onClick={(clickEvent) => this.addFile(clickEvent, e)}
                      >
                        <ListItemIcon style={{ color: "#0078D7" }}>
                          <CheckBox
                            disabled={this.state.selCase !== null}
                            style={{ color: "#0078D7" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={e} />
                      </ListItemButton>
                    </span>
                  </Tooltip>
                ))}
              </List>
            </Grid>
          </Grid>
          {/* Allow slide-free projects in Hist Classification Module. */}
          {/* Meta-Data will need to be entered manually. */}
          {this.props.formData.projectType === "HistoClassification" && (
            <EmptySlideCreation addFile={this.addFile} />
          )}
        </Grid>
      </DialogContent>
    );
  }
}

StepFileSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  selectionTarget: PropTypes.string.isRequired,
  project: PropTypes.object,
  formData: PropTypes.object,
  onChangeFiles: PropTypes.func,
  onChangeCaseId: PropTypes.func,
};

export default withStyles(styles)(StepFileSelection);
