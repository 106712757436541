// StepProjectModuleSelection.jsx
// Allows the selection of a project type from the list of all available project types.

// Copyright HS Analysis GmbH, 2021
// Author: Viktor Eberhardt, Jakob Burkhardt, Valentin Haas

// Framework imports
import React from "react";
import PropTypes from "prop-types";

// External imports
import { TextValidator } from "react-material-ui-form-validator";

// Material-UI imports
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";

// HSA Imports
import ProjectTypeList from "./ProjectTypeList";

const styles = () => ({
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    height: 562,
  },
  tileBar: {
    whiteSpace: "normal !important",
  },
});

class StepProjectModuleSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.imageListItemRefs = [];
  }

  onChangeFilter = (e) => {
    let searchText = e.target.value;
    this.setState({ searchText });
    this.imageListItemRefs = [];
  };

  render() {
    const {
      classes,
      availableTypes,
      projectType,
      onProjectType,
      name,
      onChangeName,
    } = this.props;

    // Check for selected histo modules.
    let isHistoModule =
      projectType.includes("HistoClassification") ||
      projectType.includes("HistoPointCounting");

    return (
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <TextValidator
              id="TextValidatorProjectName"
              disabled={isHistoModule}
              fullWidth
              errorMessages={["This field is required"]}
              label="Enter project name"
              margin="normal"
              onChange={(e) => onChangeName(e.target.value)}
              style={{ marginTop: 0 }}
              type="text"
              validators={isHistoModule ? [] : ["required"]}
              value={isHistoModule ? "Will be filled in automatically" : name}
              variant="standard"
              // select all text if component is defined
              onFocus={(e) => e.target.select()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ position: "relative" }}>
              <SearchIcon
                style={{
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  fill: "lightgray",
                  marginTop: 20,
                }}
              />
              <TextField
                id="TextFieldSearchText"
                autoFocus
                variant="standard"
                fullWidth
                style={{
                  marginLeft: 30,
                  width: "calc(100% - 30px)",
                  marginTop: 0,
                  display: "inline-block",
                }}
                key={"searchText"}
                margin="normal"
                label={"Search Modules"}
                value={this.state.searchText}
                onChange={this.onChangeFilter}
              />
            </div>
          </Grid>
        </Grid>

        <FormHelperText style={{ marginBottom: 10 }}>
          Project Type
        </FormHelperText>
        <ProjectTypeList
          projectType={projectType}
          onProjectType={onProjectType}
          availableTypes={availableTypes}
          searchText={this.state.searchText}
        />
      </DialogContent>
    );
  }
}

StepProjectModuleSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  projectType: PropTypes.string,
  onChangeName: PropTypes.func,
  availableTypes: PropTypes.array,
  onProjectType: PropTypes.func,
};

export default withStyles(styles)(StepProjectModuleSelection);
