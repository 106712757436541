// Copyright HS Analysis GmbH, 2023
// Author: Valentin Haas

// HSA imports
import {
  convertPascalCaseKeysToCamelCase,
  convertSnakeCaseKeysToCamelCase,
  isUuid,
  validateInEnum,
  validateInstance,
  validateType,
  ValidationType,
} from "../utils/Utils";
import Structure from "./Structure";

// #region Option sets for the AI training

// Shared data structures for the AI training
// Keep in sync with:
// Source\HSA-KIT\Models\AITrainingSettings.cs
// Source\HSA-KIT\modules\hsa\core\ai\models.py
// Source\HSA-KIT\ClientApp\src\common\components\AITrainingSettings.jsx

/**
 * All principle dataset creation approaches.
 */
export const DatasetApproach = Object.freeze({
  ImageFileBased: 0,
  ImageObjectBased: 1,
  ImageSlidingWindow: 2,
  AudioFileBased: 10,
  AudioSlidingWindow: 11,
});

/**
 * Readable names for the principle dataset creation approaches.
 */
export const DatasetApproachNames = Object.freeze({
  [DatasetApproach.ImageFileBased]: "File Based",
  [DatasetApproach.ImageObjectBased]: "Object Based",
  [DatasetApproach.ImageSlidingWindow]: "Sliding Window",
  [DatasetApproach.AudioFileBased]: "File Based",
  [DatasetApproach.AudioSlidingWindow]: "Sliding Window",
});

/**
 * Abbreviations for the principle dataset creation approaches.
 */
export const DatasetApproachShort = Object.freeze({
  [DatasetApproach.ImageFileBased]: "i-fb",
  [DatasetApproach.ImageObjectBased]: "i-ob",
  [DatasetApproach.ImageSlidingWindow]: "i-sw",
  [DatasetApproach.AudioFileBased]: "a-fb",
  [DatasetApproach.AudioSlidingWindow]: "a-sw",
});

/**
 * All training model types.
 */
export const DatasetType = Object.freeze({
  ImageClassification: 0,
  ImageObjectDetection: 1,
  ImageSegmentation: 2,
  ImageInstanceSegmentation: 3,
  AudioClassification: 10,
  AudioSequenceDetection: 11,
  AudioSequenceSegmentation: 12,
  AudioSequenceInstanceSegmentation: 13,
});

/**
 * Readable names for the training model types.
 */
export const DatasetTypeNames = Object.freeze({
  [DatasetType.ImageClassification]: "Image Classification",
  [DatasetType.ImageObjectDetection]: "Image Object Detection",
  [DatasetType.ImageSegmentation]: "Image Segmentation",
  [DatasetType.ImageInstanceSegmentation]: "Image Instance Segmentation",
  [DatasetType.AudioClassification]: "Audio Classification",
  [DatasetType.AudioSequenceDetection]: "Audio Sequence Detection",
  [DatasetType.AudioSequenceSegmentation]: "Audio Sequence Segmentation",
  [DatasetType.AudioSequenceInstanceSegmentation]:
    "Audio Sequence Instance Segmentation",
});

/**
 * Abbreviations for the training model types.
 */
export const DatasetTypeShort = Object.freeze({
  [DatasetType.ImageClassification]: "img-cl",
  [DatasetType.ImageObjectDetection]: "img-od",
  [DatasetType.ImageSegmentation]: "img-seg",
  [DatasetType.ImageInstanceSegmentation]: "img-iseg",
  [DatasetType.AudioClassification]: "aud-cl",
  [DatasetType.AudioSequenceDetection]: "aud-sd",
  [DatasetType.AudioSequenceSegmentation]: "aud-seg",
  [DatasetType.AudioSequenceInstanceSegmentation]: "aud-iseg",
});

/**
 * A collection of all image dataset types.
 */
export const ImageDatasetTypes = [
  DatasetType.ImageClassification,
  DatasetType.ImageObjectDetection,
  DatasetType.ImageSegmentation,
  DatasetType.ImageInstanceSegmentation,
];

/**
 * A collection of all audio dataset types.
 */
export const AudioDatasetTypes = [
  DatasetType.AudioClassification,
  DatasetType.AudioSequenceDetection,
  DatasetType.AudioSequenceSegmentation,
  DatasetType.AudioSequenceInstanceSegmentation,
];

/**
 * A collection of all image dataset approaches.
 */
export const ImageDatasetApproaches = [
  DatasetApproach.ImageFileBased,
  DatasetApproach.ImageObjectBased,
  DatasetApproach.ImageSlidingWindow,
];

/**
 * A collection of all audio dataset approaches.
 */
export const AudioDatasetApproaches = [
  DatasetApproach.AudioFileBased,
  DatasetApproach.AudioSlidingWindow,
];

/**
 * All available target data formats for AI models.
 */
export const TargetDataFormat = Object.freeze({
  Image: 0,
  Audio: 1,
});

/**
 * Mapping of training data types to project types.
 */
const _targetDataFormatbyModuleName = Object.freeze({
  AudioAnnotator: TargetDataFormat.Audio,
});

/**
 * Returns the training data type for a given module.
 * @param {string} projectType The name of the module currently loaded to get the training data type for.
 * @returns {TargetDataFormat} The training data type for the given module. Defaults to TargetDataFormats.Image.
 */
export const targetDataFormatbyModuleName = (projectType = "") => {
  return _targetDataFormatbyModuleName[projectType] ?? TargetDataFormat.Image;
};

/**
 * All available metrics for training.
 */
export const Metrics = Object.freeze({
  Accuracy: 0,
});
export const MetricsNames = Object.freeze({
  [Metrics.Accuracy]: "Accuracy",
});

/**
 * All available loss functions for training.
 */
export const LossFunction = Object.freeze({
  CrossEntropy: 0,
  Dice: 1,
  CrossEntropyDice: 2,
});
export const LossFunctionNames = Object.freeze({
  [LossFunction.CrossEntropy]: "Cross Entropy",
  [LossFunction.Dice]: "Dice",
  [LossFunction.CrossEntropyDice]: "Cross Entropy + Dice",
});

/**
 * All available optimizers for training.
 */
export const Optimizer = Object.freeze({
  Adam: 0,
  AdamW: 1,
  SGD: 2,
});
export const OptimizerNames = Object.freeze({
  [Optimizer.Adam]: "Adam",
  [Optimizer.AdamW]: "AdamW",
  [Optimizer.SGD]: "SGD",
});

// #endregion

// region Default values
const _DefaultDatasetTypeMappings = Object.freeze({
  AudioAnnotator: DatasetType.AudioSequenceDetection,
});

/**
 * Get a default model type for a given project type.
 * @param {string} projectType The type of the project to get the default model type for.
 * @returns {DatasetType} The default model type for the given project type.
 */
export const DefaultDatasetType = (projectType = "") => {
  return (
    _DefaultDatasetTypeMappings[projectType] ??
    DatasetType.ImageInstanceSegmentation
  );
};

const _DefaultDatasetApproachMappings = Object.freeze({
  AudioAnnotator: DatasetApproach.AudioFileBased,
});

/**
 * Get a default dataset approach for a given project type.
 * @param {string} projectType The type of the project to get the default dataset approach for.
 * @returns {DatasetApproach} The default dataset approach for the given project type.
 */
export const DefaultDatasetApproach = (projectType = "") => {
  return (
    _DefaultDatasetApproachMappings[projectType] ??
    DatasetApproach.ImageSlidingWindow
  );
};

// #endregion

// #region Data structures for each individual settings page
/**
 * All parameters of AI Training that are not model specific.
 * @param {number} epochs Number of epochs to train.
 * @param {number} earlyStopping Number of epochs to wait for early stopping.
 * @param {number} batchSize Batch size for training.
 * @param {number[]} metrics Metrics to use for training.
 * @param {string[]} lossFunctions Loss functions to use for training.
 * @param {string} optimizer Optimizer to use for training.
 * @param {number} learningRate Learning rate to use for training.
 */
export class TrainingParameters {
  constructor(
    epochs = 1,
    earlyStopping = 500,
    batchSize = 2,
    metrics = [Metrics.Accuracy],
    lossFunctions = [LossFunction.CrossEntropy, LossFunction.Dice],
    optimizer = Optimizer.AdamW,
    learningRate = 1e-4
  ) {
    // Input validation
    validateType("epochs", epochs, ValidationType.Int);
    if (epochs <= 0)
      throw RangeError(
        `epochs must be of type integer > 0, received ${typeof epochs}: ${epochs}`
      );

    validateType("earlyStopping", earlyStopping, ValidationType.Int);
    if (earlyStopping < 0)
      throw RangeError(
        `earlyStopping must be of type integer >= 0, received ${typeof earlyStopping}: ${earlyStopping}`
      );

    validateType("batchSize", batchSize, ValidationType.Int);
    if (batchSize < 1)
      throw RangeError(
        `batchSize must be of type integer >= 1, received ${typeof batchSize}: ${batchSize}`
      );
    validateType("metrics", metrics, ValidationType.Array);
    if (metrics.length === 0)
      throw RangeError(
        `metrics must be of type array with at least one element, received ${typeof metrics}: ${metrics}`
      );
    metrics.forEach((metric) => {
      validateInEnum("metric", metric, Metrics);
    });

    validateType("lossFunctions", lossFunctions, ValidationType.Array);
    if (lossFunctions.length === 0)
      throw RangeError(
        `lossFunctions must be of type array with at least one element, received ${typeof lossFunctions}: ${lossFunctions}`
      );
    lossFunctions.forEach((lossFunction) => {
      validateInEnum("lossFunction", lossFunction, LossFunction);
    });

    validateInEnum("optimizer", optimizer, Optimizer);

    validateType("learningRate", learningRate, ValidationType.Number);
    if (learningRate <= 0)
      throw RangeError(
        `learningRate must be of type number > 0, received ${typeof learningRate}: ${learningRate}`
      );

    this.epochs = epochs;
    this.earlyStopping = earlyStopping;
    this.batchSize = batchSize;
    this.metrics = metrics;
    this.lossFunctions = lossFunctions;
    this.optimizer = optimizer;
    this.learningRate = learningRate;
  }

  /**
   * Create new TrainingParameters from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per TrainingParameters constructor.
   * @param {object} obj An object containing all necessary properties of TrainingParameters.
   * @returns {TrainingParameters} New TrainingParameters.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);
    return new TrainingParameters(
      obj.epochs,
      obj.earlyStopping,
      obj.batchSize,
      obj.metrics,
      obj.lossFunctions,
      obj.optimizer,
      obj.learningRate
    );
  }
}

/**
 * All meta data of an AI Training.
 * @param {string} name Name of the AI Model.
 * @param {string} version Version of the AI Model.
 * @param {string} description Description of the AI Model.
 * @param {boolean} isNewModel Whether the model is new or not.
 */
export class ModelMetaData {
  constructor(name = "", version = "", description = "", isNewModel = true) {
    // Input validation
    validateType("name", name, ValidationType.String);
    validateType("version", version, ValidationType.String);
    validateType("description", description, ValidationType.String);
    validateType("isNewModel", isNewModel, ValidationType.Bool);

    this.name = name;
    this.version = version;
    this.description = description;
    this.isNewModel = isNewModel;
  }

  /**
   * Create new ModelMetaData from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per ModelMetaData contructor.
   * @param {object} obj An object containing all necessary properties of ModelMetaData.
   * @returns {ModelMetaData} New ModelMetaData.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new ModelMetaData(
      obj.name,
      obj.version,
      obj.description,
      obj.isNewModel
    );
  }
}

/**
 * All dataset specific parameters of an AI Training.
 * @param {DatasetApproach} datasetApproach Approach to create the dataset. Must be one of the values of DatasetApproach. Default: ImageFileBased.
 * @param {DatasetType} datasetType Type of the dataset used. Must be one of the values of DatasetType. Default: ImageClassification.
 * @param {boolean} datasetOnly Whether only the dataset should be created or not, without training. Default: false.
 * @param {boolean} useExistingDataset Whether an existing dataset should be used or not for training. Default: false.
 * @param {boolean} removeBackground Whether the background should be removed from the dataset images or not. Default: true.
 * @param {number} removeBorderThreshold Threshold for the border removal. Default: 75.
 */
export class DatasetParameters {
  constructor(
    datasetApproach = DatasetApproach.ImageSlidingWindow,
    datasetType = DatasetType.ImageClassification,
    datasetOnly = false,
    useExistingDataset = false,
    removeBackground = true,
    removeBorderThreshold = 75
  ) {
    // Input validation
    validateInEnum("datasetApproach", datasetApproach, DatasetApproach);
    validateInEnum("datasetType", datasetType, DatasetType);
    validateType("datasetOnly", datasetOnly, ValidationType.Bool);
    validateType("useExistingDataset", useExistingDataset, ValidationType.Bool);
    validateType("removeBackground", removeBackground, ValidationType.Bool);
    validateType(
      "removeBorderThreshold",
      removeBorderThreshold,
      ValidationType.Number
    );

    this.datasetApproach = datasetApproach;
    this.datasetType = datasetType;
    this.datasetOnly = datasetOnly;
    this.useExistingDataset = useExistingDataset;
    this.removeBackground = removeBackground;
    this.removeBorderThreshold = removeBorderThreshold;
  }

  /**
   * Create new DatasetParameters from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per DatasetParameters constructor.
   * @param {object} obj An object containing all necessary properties of DatasetParameters.
   * @returns {DatasetParameters} New DatasetParameters.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new DatasetParameters(
      obj.datasetApproach,
      obj.datasetType,
      obj.datasetOnly,
      obj.useExistingDataset,
      obj.removeBackground,
      obj.removeBorderThreshold
    );
  }
}

/**
 * General class for all dataset specific parameters of an AI Training.
 */
export class DatasetMeta {
  constructor() {}
}

/**
 * All model specific parameters of an image model AI Training.
 * @param {DatasetType} datasetType Type of the dataset used. Must be one of the values of DatasetType. Default: ImageClassification.
 * @param {number} inputChannels Number of input channels. Can be 1 for grayscale images, 3 for RGB images, or more for multispectral images.
 * @param {Array[string]} fluorescenceChannels List of fluorescence channel names. Default: [].
 * @param {number} spatialDims Number of spatial dimensions. Can be 2 for 2D images or 3 for 3D images.
 * @param {number} imageWidth Width of an dataset image.
 * @param {number} imageHeight Height of an dataset image.
 * @param {number} numberOfClasses Number of classes to predict.
 * @param {number} pyramidLevel Pyramid level of the dataset images. Default: -1.
 * @param {number} pixelSize Pixel size of the dataset images. Default: -1.
 */
export class ImageDatasetMeta extends DatasetMeta {
  constructor(
    datasetType = DatasetType.ImageClassification,
    inputChannels = 3,
    fluorescenceChannels = [],
    spatialDims = 2,
    imageWidth = 512,
    imageHeight = 512,
    numberOfClasses = 1,
    pyramidLevel = -1,
    pixelSize = -1
  ) {
    super();

    // Input validation
    validateInEnum("datasetType", datasetType, DatasetType);
    validateType("inputChannels", inputChannels, ValidationType.Int);
    if (inputChannels < 1)
      throw TypeError(
        `inputChannels must be of type integer >= 1, received ${typeof inputChannels}: ${inputChannels}`
      );

    validateType(
      "fluorescenceChannels",
      fluorescenceChannels,
      ValidationType.Array
    );
    fluorescenceChannels.forEach((fluorescenceChannel) => {
      validateType(
        "fluorescenceChannel",
        fluorescenceChannel,
        ValidationType.String
      );
    });

    validateType("spatialDims", spatialDims, ValidationType.Int);
    if (spatialDims < 2 || spatialDims > 3)
      throw RangeError(
        `spatialDims must be of type integer >= 2 and <= 3, received ${typeof spatialDims}: ${spatialDims}`
      );

    validateType("imageWidth", imageWidth, ValidationType.Int);
    if (imageWidth < 1)
      throw RangeError(
        `imageWidth must be of type integer >= 1, received ${typeof imageWidth}: ${imageWidth}`
      );

    validateType("imageHeight", imageHeight, ValidationType.Int);
    if (imageHeight < 1)
      throw RangeError(
        `imageHeight must be of type integer >= 1, received ${typeof imageHeight}: ${imageHeight}`
      );

    validateType("numberOfClasses", numberOfClasses, ValidationType.Int);
    if (numberOfClasses < 1)
      throw RangeError(
        `numberOfClasses must be of type integer >= 1, received ${typeof numberOfClasses}: ${numberOfClasses}`
      );

    validateType("pyramidLevel", pyramidLevel, ValidationType.Int);
    if (pyramidLevel < -1)
      throw RangeError(
        `pyramidLevel must be of type integer >= -1, received ${typeof pyramidLevel}: ${pyramidLevel}`
      );

    validateType("pixelSize", pixelSize, ValidationType.Number);

    // Set parameters
    this.datasetType = datasetType;
    this.inputChannels = inputChannels;
    this.fluorescenceChannels = fluorescenceChannels;
    this.spatialDims = spatialDims;
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;
    this.numberOfClasses = numberOfClasses;
    this.pyramidLevel = pyramidLevel;
    this.pixelSize = pixelSize;
  }

  /**
   * Create new ImageDatasetMeta from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per ImageDatasetMeta constructor.
   * @param {object} obj An object containing all necessary properties of ImageDatasetMeta.
   * @returns {ImageDatasetMeta} New ImageDatasetMeta.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new ImageDatasetMeta(
      obj.datasetType,
      obj.inputChannels,
      obj.fluorescenceChannels,
      obj.spatialDims,
      obj.imageWidth,
      obj.imageHeight,
      obj.numberOfClasses,
      obj.pyramidLevel,
      obj.pixelSize
    );
  }
}

/**
 * All model specific parameters of an audio model AI Training.
 * @param {DatasetType} datasetType Type of the dataset used. Must be one of the values of DatasetType. Default: AudioClassification.
 * @param {number} sequenceLengthSeconds Length of an audio sequence in seconds. 0 for full length. Default: 0.
 * @param {number} sequenceOverlapSeconds Overlap of audio sequences in seconds. Negative values for gaps between sequences. Default: 0.
 * @param {number} numberOfClasses Number of classes to predict. Default: 1.
 */
export class AudioDatasetMeta extends DatasetMeta {
  constructor(
    datasetType = DatasetType.AudioClassification,
    sequenceLengthSeconds = 0,
    sequenceOverlapSeconds = 0,
    numberOfClasses = 1
  ) {
    super();

    // Input validation
    validateInEnum("datasetType", datasetType, DatasetType);

    validateType(
      "sequenceLengthSeconds",
      sequenceLengthSeconds,
      ValidationType.Number
    );
    if (sequenceLengthSeconds < 0)
      throw RangeError(
        `sequenceLengthSeconds must be of type number >= 0, received ${typeof sequenceLengthSeconds}: ${sequenceLengthSeconds}`
      );

    validateType(
      "sequenceOverlapSeconds",
      sequenceOverlapSeconds,
      ValidationType.Number
    );

    validateType("numberOfClasses", numberOfClasses, ValidationType.Int);
    if (numberOfClasses < 1)
      throw RangeError(
        `numberOfClasses must be of type integer >= 1, received ${typeof numberOfClasses}: ${numberOfClasses}`
      );

    // Set parameters
    this.datasetType = datasetType;
    this.sequenceLengthSeconds = sequenceLengthSeconds;
    this.sequenceOverlapSeconds = sequenceOverlapSeconds;
    this.numberOfClasses = numberOfClasses;
  }

  /**
   * Create new AudioDatasetMeta from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase, and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AudioDatasetMeta contructor.
   * @param {object} obj An object containing all necessary properties of AudioDatasetMeta.
   * @returns {AudioDatasetMeta} New AudioDatasetMeta.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    return new AudioDatasetMeta(
      obj.datasetType,
      obj.sequenceLengthSeconds,
      obj.sequenceOverlapSeconds,
      obj.numberOfClasses
    );
  }
}

/**
 * Mapping of model parameters to dataset types.
 */
const _ModelParameterMappping = Object.freeze({
  [DatasetType.ImageClassification]: new ImageDatasetMeta(
    DatasetType.ImageClassification
  ),
  [DatasetType.ImageObjectDetection]: new ImageDatasetMeta(
    DatasetType.ImageObjectDetection
  ),
  [DatasetType.ImageSegmentation]: new ImageDatasetMeta(
    DatasetType.ImageSegmentation
  ),
  [DatasetType.ImageInstanceSegmentation]: new ImageDatasetMeta(
    DatasetType.ImageInstanceSegmentation
  ),
  [DatasetType.AudioClassification]: new AudioDatasetMeta(
    DatasetType.AudioClassification
  ),
  [DatasetType.AudioSequenceDetection]: new AudioDatasetMeta(
    DatasetType.AudioSequenceDetection
  ),
  [DatasetType.AudioSequenceSegmentation]: new AudioDatasetMeta(
    DatasetType.AudioSequenceSegmentation
  ),
  [DatasetType.AudioSequenceInstanceSegmentation]: new AudioDatasetMeta(
    DatasetType.AudioSequenceInstanceSegmentation
  ),
});

/**
 * Returns the model parameters for a given dataset type.
 * @param {DatasetType} datasetType The type of the dataset to get the model parameters for.
 * @returns {DatasetMeta}
 */
export const getModelParameters = (datasetType) => {
  return _ModelParameterMappping[datasetType] ?? new DatasetMeta();
};

// #endregion

/**
 * All settings of an AI Training.
 * @param {Uuid} projectId ID of the project to train on.
 * @param {string} projectType Type of the project to train on.
 * @param {ModelMetaData} metaData Meta data of the AI Model.
 * @param {DatasetParameters} datasetParameters Dataset specific parameters of the AI Training.
 * @param {TrainingParameters} trainingParameters Training specific parameters of the AI Training.
 * @param {DatasetMeta} modelParameters Model specific parameters of the AI Training.
 * @param {Structure[]} structures Structures to train on.
 */
export default class AITrainingSettings {
  constructor(
    projectId,
    projectType,
    metaData = new ModelMetaData(),
    datasetParameters = new DatasetParameters(),
    trainingParameters = new TrainingParameters(),
    modelParameters = new DatasetMeta(),
    structures = []
  ) {
    // Input validation
    if (!isUuid(projectId, true))
      throw TypeError(
        `projectId must be of type uuid, received ${typeof projectId}: ${projectId}`
      );

    validateType("projectType", projectType, ValidationType.String);
    validateInstance("metaData", metaData, ModelMetaData);
    validateInstance("datasetParameters", datasetParameters, DatasetParameters);
    validateInstance(
      "trainingParameters",
      trainingParameters,
      TrainingParameters
    );
    validateInstance("modelParameters", modelParameters, DatasetMeta);
    validateType("structures", structures, ValidationType.Array);
    structures.forEach((structure) => {
      validateInstance("structure", structure, Structure);
    });

    // Set parameters
    this.projectId = projectId;
    this.projectType = projectType;
    this.metaData = metaData;
    this.datasetParameters = datasetParameters;
    this.trainingParameters = trainingParameters;
    this.modelParameters = modelParameters;
    this.structures = structures;
  }

  /**
   * Create new AITrainingSettings from an object without needing to define all parameters individually.
   * Accepts camelCase, PascalCase and snake_case properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AITrainingSettings constructor.
   * @param {object} obj An object containing all necessary properties of AITrainingSettings.
   * @returns {AITrainingSettings} New AITrainingSettings.
   */
  static fromObject(obj) {
    obj = convertPascalCaseKeysToCamelCase(obj);
    obj = convertSnakeCaseKeysToCamelCase(obj);

    validateType("obj.structures", obj.structures, ValidationType.Array);

    obj.structures = obj.structures.map((structure) =>
      Structure.fromObject(structure)
    );

    obj.datasetParameters = DatasetParameters.fromObject(obj.datasetParameters);

    if (ImageDatasetTypes.includes(obj.datasetParameters.datasetType)) {
      obj.modelParameters = ImageDatasetMeta.fromObject(obj.modelParameters);
    } else if (AudioDatasetTypes.includes(obj.datasetParameters.datasetType)) {
      obj.modelParameters = AudioDatasetMeta.fromObject(obj.modelParameters);
    }

    return new AITrainingSettings(
      obj.projectId,
      obj.projectType,
      ModelMetaData.fromObject(obj.metaData),
      obj.datasetParameters,
      TrainingParameters.fromObject(obj.trainingParameters),
      obj.modelParameters,
      obj.structures
    );
  }
}
