// ProjectsTableRow.jsx

// Copyright HS Analysis GmbH, 2019
// Authors: Viktor Eberhardt

// Framework imports
import React from "react";
import PropTypes from "prop-types";

// Mui imports
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TimerIcon from "@mui/icons-material/Timer";
import Tooltip from "@mui/material/Tooltip";

// HSA imports
import { convertDate } from "../../common/utils/Localization";
import Backend from "../../common/utils/Backend";
import CustomSvgIcon from "../../globalComponents/CustomSvgIcon";
import PreviewImage from "./PreviewImage";

const ProjectsTableRow = (props) => {
  const {
    classes,
    projectTypes,
    isSelected,
    project,
    handleClick,
    handleSelectOneClick,
    onEditProject,
    history,
    handleActiveTabChange,
    user,
  } = props;

  /**
   * Returns Label of a project type
   * @param {String} type Project type string
   */
  const labelFromProjectType = () => {
    if (!projectTypes) return project.type;
    let resultObject = projectTypes.find((c) => c.name === project.type);
    return resultObject ? resultObject.label : project.type;
  };

  const handleContextmenu = (e) => {
    e.preventDefault();
    handleSelectOneClick(e, project);
  };

  const onCancelRowClick = (e) => {
    e.stopPropagation();
    Backend.cancelJob(project.id);
  };

  // Sets job state for project to pending, so that it can be run, when no other job is running
  const onRunRowClick = (e) => {
    e.stopPropagation();
    props.onRunRowClick(e, [project.id], project);
  };

  const onDuplicateSingleClick = (e) => {
    e.stopPropagation();
    Backend.duplicateProjects([project.id], () => {
      handleActiveTabChange(0);
    });
  };

  const onProjectStateClick = (e, id) => {
    e.stopPropagation();
    Backend.loadProjectMessage(id)
      .then((jsonResponse) => {
        if (jsonResponse.message) {
          console.log(jsonResponse.message);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const onProjectErrorStateClick = (e, id) => {
    e.stopPropagation();
    Backend.loadProjectMessage(id)
      .then((jsonResponse) => {
        if (jsonResponse.message) {
          window.openErrorDialog(jsonResponse.message);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  /**
   * Set job state for project to timed
   * @param {MouseEvent} e
   */
  const onSetTimedClick = (e) => {
    e.stopPropagation();
    Backend.setProjectsTimed([project.id], () => {
      console.debug(
        `Project ${project.readableId}, (${project.id}) state updated: timed`
      );
    });
  };

  //Updates label style according to project state
  const labelFromState = () => {
    switch (project.state) {
      case "creating":
        return (
          <Tooltip disableInteractive title={`Importing: ${project.progress}%`}>
            <div
              className={classes.progressBarContainer}
              onClick={(e) => onProjectStateClick(e, project.id)}
            >
              <LinearProgress
                style={{
                  height: 20,
                  width: "100%",
                }}
                classes={{ bar: classes.creatingJobBar }}
                variant="determinate"
                value={project.progress}
              />
              <div className={classes.progressBar}>{project.progress}%</div>
            </div>
          </Tooltip>
        );
      case "running":
        return (
          <Tooltip
            disableInteractive
            title={`Running job: ${project.progress.toFixed(2, 10)}%`}
          >
            <div
              className={classes.progressBarContainer}
              onClick={(e) => onProjectStateClick(e, project.id)}
            >
              <LinearProgress
                style={{
                  height: 20,
                  width: "100%",
                }}
                variant="determinate"
                value={project.progress}
              />
              <div className={classes.progressBar}>{project.progress}%</div>
            </div>
          </Tooltip>
        );
      case "success":
        return (
          <span
            style={{ color: "rgb(71, 214, 71)" }}
            onClick={(e) => onProjectStateClick(e, project.id)}
          >
            Finished
          </span>
        );
      case "error":
        return (
          <Button
            style={{ color: "#ff0000" }}
            onClick={(e) => onProjectErrorStateClick(e, project.id)}
          >
            {project.state}
          </Button>
        );
      default:
        return (
          <span onClick={(e) => onProjectStateClick(e, project.id)}>
            {project.state}
          </span>
        );
    }
  };

  return (
    <TableRow
      className={classes.tableRow}
      style={{
        opacity: project.canOpen ? "1" : "0.5",
      }}
      hover
      onClick={(event) => handleClick(event, project)}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={project.id}
      selected={isSelected}
      onContextMenu={handleContextmenu}
    >
      <TableCell padding="checkbox">
        <Tooltip
          disableInteractive
          title={project.canOpen ? "" : "No Permission!"}
        >
          <span>
            <Checkbox
              name="CheckboxProjectRow"
              disabled={!project.canOpen}
              checked={isSelected}
              onClick={(event) => handleSelectOneClick(event, project)}
            />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="right">{project.readableId}</TableCell>
      <TableCell style={{ padding: "0" }}>
        <Tooltip
          disableInteractive
          title={
            <React.Fragment>
              {project.imageFileIds
                .slice(0, Math.min(5, project.imageFileIds.length))
                .map((fileId, idx) => {
                  return <PreviewImage fileId={fileId} key={idx} />;
                })}
            </React.Fragment>
          }
        >
          {
            <img
              style={{
                background: "black",
                margin: "2px",
                objectFit: "contain",
              }}
              onError={(event) => (event.target.style.display = "none")}
              width="60"
              height="60"
              src={Backend.renderThumbnail(
                project.imageFileIds.length > 0 ? project.imageFileIds[0] : 0
              )}
              alt=""
            />
          }
        </Tooltip>
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <div
          style={{
            display: "inline-block",
            width: "calc(100% - 48px)",
          }}
        >
          {project.name}
        </div>

        {!project.type.includes("HistoPointCounting") &&
          !project.type.includes("HistoClassification") && (
            <Tooltip
              disableInteractive
              title={project.canEdit ? "" : "No Permission!"}
            >
              <span>
                <IconButton
                  disabled={!project.canEdit}
                  aria-label="Edit Project Name"
                  onClick={(e) => {
                    onEditProject(project);
                    e.stopPropagation();
                  }}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
      </TableCell>
      <TableCell align="right">{project.ownerName}</TableCell>
      <TableCell align="right">{labelFromProjectType()}</TableCell>
      <TableCell align="right">{labelFromState()}</TableCell>
      <TableCell align="right">
        {convertDate(project.creationDateTime).toLocaleString()}
      </TableCell>
      <TableCell align="right">
        {project.state === "success" && project.type !== "ProteomeAnalysis" ? (
          <Tooltip
            disableInteractive
            title={project.canAccessResults ? "Result" : "No Permission"}
          >
            <span>
              <IconButton
                disabled={!project.canAccessResults}
                aria-label="Result"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/report/" + project.id);
                }}
                size="large"
              >
                <CustomSvgIcon
                  name="ResultsIcon"
                  width={22.86}
                  height={23.8}
                  vertAlign="-4px"
                />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}
        {project.state === "success" && (
          <Tooltip disableInteractive title="Duplicate project">
            <span>
              <IconButton
                aria-label="Duplicate Project"
                onClick={onDuplicateSingleClick}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!project.type.includes("HistoPointCounting") &&
          !project.type.includes("HistoClassification") && (
            <span>
              {project.state === "running" ||
              project.state === "timed" ||
              project.state === "pending" ? (
                <Tooltip disableInteractive title="Cancel job">
                  <span>
                    <IconButton
                      aria-label="Stop"
                      onClick={onCancelRowClick}
                      size="large"
                    >
                      <StopIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <span>
                  {!project.canRun ||
                  project.state === "running" ||
                  project.state === "pending" ? (
                    <Tooltip
                      disableInteractive
                      title={
                        project.canRun && user.group.canRunJob
                          ? ""
                          : "No Permission"
                      }
                    >
                      <span>
                        <IconButton
                          disabled={true}
                          aria-label="Timer"
                          onClick={onSetTimedClick}
                          size="large"
                        >
                          <TimerIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      disableInteractive
                      title="Run project within time "
                    >
                      <span>
                        <IconButton
                          aria-label="Timer"
                          disabled={user.group.canRunJob === false}
                          hidden={
                            project.state === "success" &&
                            project.type.includes("SonyQD")
                              ? true
                              : false
                          }
                          onClick={(e) => onSetTimedClick(e, project.id)}
                          size="large"
                        >
                          <TimerIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  <Tooltip
                    disableInteractive
                    title={
                      project.canRun && user.group.canRunJob
                        ? project.state === "success"
                          ? "Optionally re-run analysis and re-create report"
                          : "Run analysis and create report"
                        : "No Permission!"
                    }
                  >
                    <span>
                      <IconButton
                        aria-label="Play"
                        disabled={
                          !project.canRun ||
                          project.state === "running" ||
                          project.state === "pending" ||
                          user.group.canRunJob === false
                        }
                        hidden={
                          project.state === "success" &&
                          project.type.includes("SonyQD")
                            ? true
                            : false
                        }
                        onClick={onRunRowClick}
                        size="large"
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </span>
              )}
            </span>
          )}
      </TableCell>
    </TableRow>
  );
};

ProjectsTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  projectTypes: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSelectOneClick: PropTypes.func.isRequired,
  onEditProject: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  handleActiveTabChange: PropTypes.func.isRequired,
  onRunRowClick: PropTypes.func,
};

export default ProjectsTableRow;
