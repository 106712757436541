function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

/**
 * Adjusts a UTC date to local timezone.
 * @param {Date} date A date object to convert.
 * @returns {Date} A date adjusted to the local timezone.
 */
export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

/**
 * Turn a json date into a standard Date object.
 * @param {object} jsonDate An date formatted as a json object.
 * @returns {Date} A Date object.
 */
export function convertDate(jsonDate) {
  return new Date(jsonDate);
}

export function convertDateToDayString(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

/**
 * Converts a given local date to a short ISO 8601 formatted string.
 * @param {Date} date Date object.
 * @returns {String} Date as ISO 8601 formatted string.
 */
export function convertDateToShortIsoString(date) {
  let d = new Date(date);
  let isoString =
    d.getFullYear() +
    "-" +
    padTo2Digits(d.getMonth() + 1) +
    "-" +
    padTo2Digits(d.getDate()) +
    "_" +
    padTo2Digits(d.getHours()) +
    "-" +
    padTo2Digits(d.getMinutes()) +
    "-" +
    padTo2Digits(d.getSeconds());
  return isoString;
}
